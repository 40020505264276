import "../Assets/Style/CustomButton.scss"

const Button = ({text,width,color,height, onClick}) => {
    return (
        <button style={{width:`${width}`,backgroundColor:`${color}`,height:`${height}`}} onClick={onClick}>
           {text}
        </button>
    );
}
 
export default Button;