import { Fragment, useState } from "react"
import Moment from "react-moment"
import ShipmentNavigateButton from "./Shipment_Navigate"
import { Table } from "react-bootstrap"


const DataOne = ({data, setSelectedShipment}) => {
    const[CurrentPage,setCurrentPage]=useState(1)
    const [PostPerPage,setPostPerPage]=useState(20);
    const LastPost = CurrentPage * PostPerPage;
    const FirstPost = LastPost-PostPerPage;
    const Output = data.slice(FirstPost,LastPost)
    const Length = data.length;
    

     const render=(res)=>{
        switch (res) {
            case "Pending Pickup":
                return "Pending Pickup"
                break;
            case "Cancelled":
                return "Cancelled"
                break;
            case "Rejected":
                return "Rejected"
                break;
            case "Delivered":
                return "Delivered"
                break;
            case "Pending Approval":
                return "Pending Approval"
                break;
        
            default:
                return "Delivered"
                break;
        }
    }

    const renderColor=(res)=>{
        switch (res) {
            case "Pending Pickup":
                return "pending-pickup"
                break;
            case "Cancelled":
                return "cancel"
                break;
            case "Rejected":
                return "cancel"
                break;
            case "Delivered":
                return "success"
                break;
            case "Pending Approval":
                return "pending"
                break;
        
            default:
                return "success"
                break;
        }
    }

    return (
            <Fragment>
                <table className="d-none d-md-table" >
                <thead>
                    <th>RECEIVER</th>
                    <th>AMOUNT</th>
                    <th>STATUS</th>
                    <th>STATUS</th>
                </thead>
                <tbody>
                {   
                    Output.map(item=>(
                        <tr onClick={()=>setSelectedShipment(item)} >
                    
                    <td>
                        <div className="Box">
                            <h5>{item.recipient_name}</h5>
                            <p>{item.recipient_phone_no}</p>
                            <p>{item.drop_off_location}</p>
                        </div>
                    </td>
                    <td>
                        <div className="Box">
                            <h5>&#8358;{item.total_amount}</h5>
                            {/* <p><Moment format="h:mm a">{item.date_time}</Moment></p> */}
                        </div>
                    </td>
                    <td>
                        <div className="Box">
                            <div className={`Btn ${renderColor(item.status)}`}>{render(item.status)}</div>
                        </div>
                        </td>
                        <td>
                            <div className="Box">
                                <h5><Moment format="DD MMM YYYY">{item.date_time}</Moment></h5>
                                <p><Moment format="h:mm a">{item.date_time}</Moment></p>
                            </div>
                        </td>
                    </tr>
                    ))
                }
            </tbody>
            </table>
            <Table responsive className="d-md-none" >
                <thead>
                        <th>RECEIVER</th>
                        <th>AMOUNT</th>
                        <th>STATUS</th>
                        <th>STATUS</th>
                    </thead>
                    <tbody>
                    {   
                        Output.map(item=>(
                        <tr onClick={()=>setSelectedShipment(item)}>
                        
                            <td>
                                <div className="Box">
                                    <h5 style={{whiteSpace:"nowrap"}}>{item.recipient_name}</h5>
                                    <p>{item.recipient_phone_no}</p>
                                    <p>{item.drop_off_location}</p>
                                </div>
                            </td>
                            <td>
                                <div className="Box">
                                    <h5>&#8358;{item.total_amount}</h5>
                                    {/* <p><Moment format="h:mm a">{item.date_time}</Moment></p> */}
                                </div>
                            </td>
                            <td>
                                <div className="Box">
                                </div>
                            </td>
                            <td>
                                <div className="Box">
                                    <h5 style={{whiteSpace:"nowrap"}}><Moment format="DD MMM YYYY">{item.date_time}</Moment></h5>
                                    <p><Moment format="h:mm a">{item.date_time}</Moment></p>
                                </div>
                            </td>
                        </tr>
                        ))
                    }
                </tbody>
            </Table>
            <ShipmentNavigateButton
            CurrentPage={CurrentPage}
            setCurrentPage={setCurrentPage}
            output={Output}
            Length={Length} />
            </Fragment>
    );
}
 
export default DataOne;