import "../Assets/Style/HomeFooter.scss";
import appstore from "../Assets/Img/appstore.png";
import googlestore from "../Assets/Img/googleApp.png";
import { Link } from "react-router-dom";
import Playstore from "../Assets/Img/playstore.png";
import AppleStore from "../Assets/Img/applestore.png";
import { FaInstagram, FaFacebookSquare } from "react-icons/fa";

const HomeFooter = () => {
  return (
    <div className="Footer_Wrapper">
      <div className="Inner_Container">
        <div className="Left">
          <div className="Apps">
            <img src={Playstore} alt="google play" />
            <img src={AppleStore} alt="appstore" />
          </div>
          <div className="Copyright">
            <p>
              Zee Express Logistics & Procurement Limited
              <br />© 2023 All rights reserved
            </p>
          </div>
        </div>
        <div className="Right">
          <ul className="BottomLinks" >
            <li>
              <Link to="/" >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/" >
              Terms & Conditions
              </Link>
            </li>
          </ul>
          <div className="FooterSocials" >
            <a href="#">
              <FaInstagram />
            </a>
            <a href="#">
              <FaFacebookSquare />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
