import TabCard from "./TabCard";
import Data from "./TabDetails"
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const NavigatingTabs = ({Tab,setTab}) => {
    const PickIndex=(index)=>{
      setTab(index)
      console.log('pick index',index)
    }

     useEffect(() => {
        AOS.init({
          duration : 2000
        });
        AOS.refresh();
      }, []);

    return (
        <div className="Tabs_Wrap">
            {
                Data.map((item,index)=><TabCard 
                handle={()=>PickIndex(index)}
                Target={index}
                Selected={Tab}
                active='active'
                key={index}
                item={item}
                />)
            }
        </div>
    );
}
 
export default NavigatingTabs;