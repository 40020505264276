import { useState } from "react";
import "../../../Assets/Style/Confirm.scss"
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionID } from "../../../Library/Utilities";
import { BookLocalShipment,ClearRecord } from "../../../store/LocalShipmentAction";

const ConfirmSection = ({setTab}) => {
    const dispatch = useDispatch()

    const user_id=localStorage.getItem('user_id');
    const user_ses_id=localStorage.getItem('session_id')
    const company_id=localStorage.getItem('company_id')
    const mail=localStorage.getItem('email')
    const transaction_id=generateTransactionID(15)
    const {
        isLoading,
        isBook,
        PaymentOption,
        PriceDetails,
        SenderAndReceiverDetails,
        Locations,} = useSelector((state)=>state.Local)




    const HomeHandle=()=>{
        dispatch(ClearRecord())
        setTab(0)
    }

    const BookShipment=()=>{
        const data={
            "user_id":user_id,
            "company_id":company_id,
            "transaction_id":transaction_id,
            "user_ses_id":user_ses_id,
            "pick_up_location":Locations.pickup,
            "drop_off_locations":Locations.dropoff,
            "recipient_name":SenderAndReceiverDetails.receiver_name,
            "recipient_phone_no":SenderAndReceiverDetails.receiver_number,
            "sender_name":SenderAndReceiverDetails.sender_name,
            "sender_phone_no":SenderAndReceiverDetails.sender_number,
            "standard_fee":PriceDetails.flutter_amount,
            "amount":PriceDetails.amount,
            "extra_fee":PriceDetails.total_amount,
            "delivery_time":Locations.deliver,
            "payment_method":PaymentOption,
            "promo_code":Locations.promo,
            "user_type":"sender",
            "chosen_date":"",
            "transaction_reference_no":"",
            "item_name":SenderAndReceiverDetails.package,
        }
        dispatch(BookLocalShipment(data))
    }

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);

    return (
        <>
        { !isBook && <div data-aos="fade-zoom-in" className="Confirm">
            <h2>SUMMARY</h2>
            <div className="Box">
                <p>SENDER DETAILS</p>
                <div className="Right">
                    <h4>{SenderAndReceiverDetails.sender_name.toUpperCase()}</h4>
                    <h6>{SenderAndReceiverDetails.sender_number}</h6>
                </div>
            </div>
            <div className="Box">
                <p>RECEIVER DETAILS</p>
                <div className="Right">
                <h4>{SenderAndReceiverDetails.receiver_name.toUpperCase()}</h4>
                <h6>{SenderAndReceiverDetails.receiver_number}</h6>
                </div>
            </div>
            <div className="Box">
                <p>PACKAGE</p>
                <div className="Right">
                <h6>{SenderAndReceiverDetails.package}</h6>              
                </div>
            </div>
            <div className="Box">
                <p>PAYMENT MENTHOD</p>
                <div className="Right">
                <h4>{PaymentOption}</h4>               
                </div>
            </div>
            <div className="Bottom_Box">
                <div className="Left">
                By continuing, I represent that the declaration above is a proper and accurate description of the contents of my package.
                </div>
                <div className="Right">
                    <h3>NGN {PriceDetails.amount}</h3>
                    <h5>Inc. VAT</h5>
                </div>
            </div>
            <button onClick={BookShipment}>
                {
                    isLoading===true ?<span className="spinner-border spinner-grow-sm"/> :'BOOK SHIPMENT'
                }
            </button>
        </div>}
        {
         isBook && <div data-aos="fade-zoom-in" className="Successfull">
            <h4>Shipment as been book Successfully</h4>
            <p>You can go ahead</p>
            <p>to track your delivery</p>
            <div onClick={HomeHandle} className="Btn">Back</div>
         </div>
        }
        </>
    );
}
 
export default ConfirmSection;