import { Link } from "react-router-dom";


const Card = ({item,active,handleClick}) => {
    const {title,Svg,text,title_Svg,link} = item
    return (
       <Link to={link} className="w-100" >
        <div onClick={handleClick} className={`Custom_Card ${active}`}>
            <div className="Inner_Card w-100 pe-4">
                <div className="Img_Box">
                   {Svg}
                </div>
                <div className="Detail_Box w-100">
                    <h3 className="d-flex justify-content-between w-100" >{title} {title_Svg}</h3>
                    <p>{text}</p>
                </div>
            </div>
        </div>
       </Link>
    );
}
 
export default Card;