import "../../../Assets/Style/PaymentMethod.scss"
import AOS from "aos";
import "aos/dist/aos.css";
import { NumericFormat } from 'react-number-format';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionID } from "../../../Library/Utilities";
import { getWalletBalanceURL } from "../../../Library/URLs";
import axios from "axios";
import { InternationalActions } from "../../../store/International_Slice";

const PaymentMethod = ({setTab}) => {

  const Paymethod = useSelector((state) => state.International.PaymentOption)
  const dispatch = useDispatch()
  const [Balance,setBalance]=useState('')

  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])

  useEffect(()=>{
    const GetWallet=async()=>{
     const Data={
         "user_id":localStorage.getItem('user_id'),
         "user_ses_id":localStorage.getItem('session_id'),
         "company_id":localStorage.getItem('company_id'),
         "transaction_id":generateTransactionID(15),
     }
         await axios
         .post(getWalletBalanceURL,Data)
         .then(response=>{
             const res=response.data.data
             setBalance(res)
         })
     }
 
     GetWallet()
     },[])

  const SubmitForm = () => {
    if (Paymethod === '') {
      return
    } else {
      setTab(4)
    }
  }

    return (
        <div data-aos="fade-zoom-in" className="Payment_Method">
      <h3>AVAILABLE PAYMENT MENTHOD</h3>
      <div className="Box">
        <div className="Detail">
          <h4>Debit card</h4>
          <h5>Pay using Mastercard, Visa or Verve card</h5>
        </div>
        <input
          value="Card Payment"
          onChange={(e) =>
            dispatch(InternationalActions.GetPayment(e.target.value))
          }
          name="radio"
          type="radio"
          checked={Paymethod === 'Card Payment' ? true : false}
        />
      </div>
      <div className="Box">
        <div className="Detail">
          <h4>Virtual bank transfer</h4>
          <h5>Pay by sending funds to a virtual bank account</h5>
        </div>
        <input
          value="Direct Transfer"
          onChange={(e) =>
            dispatch(InternationalActions.GetPayment(e.target.value))
          }
          name="radio"
          type="radio"
          checked={Paymethod === 'Direct Transfer' ? true : false}
        />
      </div>
      <div className="Box">
        <div className="Detail">
          <h4>Wallet</h4>
          <h5>Pay by sending funds to a virtual bank account</h5>
        </div>
        <input
          value="Wallet"
          onChange={(e) =>
            dispatch(InternationalActions.GetPayment(e.target.value))
          }
          name="radio"
          type="radio"
          checked={Paymethod === 'Wallet' ? true : false}
        />
      </div>
      {
        Balance!=='' && <div className="Balance">
        <div className="Wrap">
        <h2><NumericFormat value={Balance.account_balance} displayType={'text'} thousandSeparator={true} prefix={''} /></h2><h5>NGN</h5>
        </div>
        <h6 className={`${Balance.account_balance < 100 ? 'Danger_Balance':''}`}>{Balance.account_balance < 100 ? 'Insufficient balance':'Available balance'}</h6>
        </div>
      }
      <small>
        By continuing, I represent that the declaration above is a proper and
        accurate description of the contents of my package.
      </small>
      <button onClick={SubmitForm}>CONTINUE</button>
      <button
          className="PrevBtn"
          style={{ backgroundColor: "#225AA4" }}
          onClick={(e) => {
            e.preventDefault();
            setTab(2);
          }}
          type="button"
        >
          PREVIOUS
        </button>
    </div>
    );
}
 
export default PaymentMethod;