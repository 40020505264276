import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../Assets/Style/LocalShipment.scss";
import {
  Sender,
  Receiver,
  Package,
  Payment,
  Confirm,
} from "./components/index";
import NavigatingTabs from "./components/Tabs";
import { Accordion } from "react-bootstrap";
// import Button from "../../components/Button"
// import { InterStateActions } from "../../store/Sender_Slice";

const CreateInterstateShipment = () => {
  const { PriceDetails } = useSelector((state) => state.Interstate);
  // const dispatch = useDispatch()
  const [Tab, setTab] = useState(0);

  return (
    <div className="Local_Shipment_Wrapper">
      <span className="d-md-none mb-3" >
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="py-2" >Create Shipment Progress</Accordion.Header>
            <Accordion.Body>
              <NavigatingTabs setTab={setTab} Tab={Tab} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </span>
      <div className="Dynamic_Display_Wrap mt-3">
        <h3>
          {Tab === 0 && "Enter Sender Details"}
          {Tab === 1 && "Enter Receiver Details"}
          {Tab === 2 && "Enter Package Details"}
          {Tab === 3 && "Choose Payment Method"}
          {Tab === 4 && "Confirm Booking Details"}

          {/* {Tab > 0 && <span onClick={()=>setTab(pre=>pre-1)}>< Button text="Back" width="120px" color="green"/></span>} */}
        </h3>
        {PriceDetails !== "" && (
          <div className="Price_Param">
            Cost: NGN
            {PriceDetails.selling_price}
          </div>
        )}
      </div>
      <div className="Wrap">
        <div className="Left">
          <span className="d-none d-md-block" >
            <NavigatingTabs setTab={setTab} Tab={Tab} />
          </span>
          <div className="Terms d-none d-md-block">
            By completing this transaction, you represent that you have read,
            understand and accept the Zee Express{" "}
            <Link to="/local-shipment">terms of service</Link> and{" "}
            <Link to="/local-shipment">privacy policy</Link>.
          </div>
        </div>
        <div className="Right">
          {Tab === 0 && <Sender set={setTab} />}
          {Tab === 1 && <Receiver set={setTab} />}
          {Tab === 2 && <Package set={setTab} />}
          {Tab === 3 && <Payment set={setTab} />}
          {Tab === 4 && <Confirm set={setTab} />}
          <div className="Terms d-md-none">
            By completing this transaction, you represent that you have read,
            understand and accept the Zee Express{" "}
            <Link to="/local-shipment">terms of service</Link> and{" "}
            <Link to="/local-shipment">privacy policy</Link>.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInterstateShipment;
