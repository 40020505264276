import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist"
import { combineReducers } from "@reduxjs/toolkit";
import LocalShipmentReducer from "./Local_Slice"
import InterStateReducer from "./InterState_Slice"
import InternationalReducer from "./International_Slice"


const persistConfig={
    key:"root",
    version:1,
    storage
}

const reducer = combineReducers({
    Local:LocalShipmentReducer,
    Interstate:InterStateReducer,
    International:InternationalReducer
});

const persistedReducer=persistReducer(persistConfig,reducer);

export const store = configureStore({
    reducer:persistedReducer
})