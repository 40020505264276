import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import {
  calculateLocalCostURL,
  getAddressSuggestionsURL,
} from "../../../Library/URLs";
import {
  generateTransactionID,
  UserProfileData,
} from "../../../Library/Utilities";
import "../../../Assets/Style/CreateLocalShipment.scss";
import { LocalShipmentDataStorage } from "../../../Library/LocalShipmentDataStorage";
import { useDispatch, useSelector } from "react-redux";
import { LocalShipmentActions } from "../../../store/Local_Slice";

const SenderForm = ({ setTab, setShipmentFee }) => {
  const dispatch = useDispatch();
  const SenderLocations = useSelector((state) => state.Local.Locations);

  const form = useRef(null);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [isClear, setIsClear] = useState(false);
  const [isCalculatingShipment, setIsCalculatingShipment] = useState(false);
  const [isShipmentCalculated, setIsShipmentCalculated] = useState(false);

  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");

  const [showDropoffSuggestions, setShowDropoffSuggestions] = useState(false);
  const [showPickupSuggestions, setShowPickupSuggestions] = useState(false);

  const [pickupAddressSuggestions, setPickupAddressSuggestions] = useState([]);
  const [dropoffAddressSuggestions, setDropoffAddressSuggestions] = useState(
    []
  );

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });

    const pickupLocation = LocalShipmentDataStorage.getPickupLocation();
    const dropoffLocation = LocalShipmentDataStorage.getDropoffLocation();
    setPickupLocation(pickupLocation);
    setDropoffLocation(dropoffLocation);
  }, []);

  useEffect(() => {
    if (!isClear) {
      return;
    } else {
      reset();
    }
  }, [isClear, reset]);

  useEffect(() => {
    if (SenderLocations !== "") {
      setPickupLocation(SenderLocations.pickup);
      setDropoffLocation(SenderLocations.dropoff);
      // setIsShipmentCalculated(true)
    }
  }, [SenderLocations, reset]);

  const suggestPlaces = (event, type) => {
    const keyword = event.target.value;

    const formValues = {
      address: keyword,
    };

    const newData = { ...formValues, ...UserProfileData() };

    setShipmentFee("");

    if (type === "Pickup") {
      setPickupLocation(keyword);
      setShowPickupSuggestions(true);
    } else {
      setDropoffLocation(keyword);
      setShowDropoffSuggestions(true);
    }

    console.log(newData);

    axios
      .post(getAddressSuggestionsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        const predictions = response["data"]["data"];

        if (type === "Pickup") {
          setPickupAddressSuggestions(predictions);
        } else {
          setDropoffAddressSuggestions(predictions);
        }

        console.log(predictions);
      });
  };

  const selectPlace = (location, type) => {
    if (type === "Pickup") {
      setPickupLocation(location);
      setShowPickupSuggestions(false);
    } else {
      setDropoffLocation(location);
      setShowDropoffSuggestions(false);
    }
  };

  const moveTab = () => {
    setTab((pre) => pre + 1);
  };

  const calculateCost = (e) => {
    e.preventDefault();
    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    formValues["transaction_id"] = generateTransactionID(15);

    const newData = { ...formValues, ...UserProfileData() };

    setIsCalculatingShipment(true);

    console.log(newData);

    axios
      .post(calculateLocalCostURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsCalculatingShipment(false);
        if (response["data"]["success"] === true) {
          const amount = response["data"]["data"]["amount"];
          dispatch(
            LocalShipmentActions.GetLocation({
              promo: response.data.data.promo_id,
              deliver: response.data.data.day_of_delivery,
              pickup: response.data.data.pick_up_location,
              dropoff: response.data.data.drop_off_location,
            })
          );
          dispatch(
            LocalShipmentActions.GetPriceDetails({
              amount: response.data.data.amount,
              total_amount: response.data.data.total_amount,
              flutter_amount: response.data.data.flutterwave_amount,
            })
          );
          setShipmentFee(amount);
          setIsShipmentCalculated(true);

          LocalShipmentDataStorage.setPickupLocation(pickupLocation);
          LocalShipmentDataStorage.setDropoffLocation(dropoffLocation);
        } else {
          toast.error(response["data"]["message"]);
        }
      });
  };

  return (
    <form
      data-aos="fade-zoom-in"
      ref={form}
      onSubmit={isShipmentCalculated ? moveTab : calculateCost}
    >
      <div className="Box">
        <label htmlFor="street">SHIPPING CODE</label>
        <input
          id="street"
          placeholder="Enter shipping code"
          onChange={(e) => {
            suggestPlaces(e, "Pickup");
          }}
          value={pickupLocation}
          className={`${errors.pickup_location ? "error" : "noError"}`}
          {...register("pickup_location", {
            required: true,
            onChange: (e) => {
              suggestPlaces(e, "Pickup");
            },
          })}
        />
        {errors.pickup_location && (
          <span className={`${errors.pickup_location ? "errorText" : ""}`}>
            Pickup location is required
          </span>
        )}
        <div
          style={{ width: "86%" }}
          className={`suggestions-field ${
            showPickupSuggestions ? "suggestions-field-visible" : null
          }`}
        >
          {pickupAddressSuggestions.map((suggestions) => {
            const mainText = suggestions.main_text;
            const description = suggestions.secondary_text;
            return (
              <div
                onClick={(e) => selectPlace(mainText, "Pickup")}
                key={suggestions.place_id}
                className="single-suggestion"
              >
                <span style={{ fontSize: 14, color: "#74788d" }}>
                  {mainText}
                </span>
                <span style={{ fontSize: 11, marginTop: -5, color: "#74788d" }}>
                  {description}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <button className="btn">
        {isCalculatingShipment === false ? (
          <span className="button-text">
            {isShipmentCalculated ? "CONTINUE" : "TRACK SHIPMENT"}{" "}
          </span>
        ) : (
          <span className="spinner-border spinner-grow-sm" />
        )}
      </button>
    </form>
  );
};

export default SenderForm;
