import "../Assets/Style/HomeHeader.scss";
import Button from "./Button";
import logo from "../Assets/Img/zeelogo.png";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";

const Header = () => {
  return (
    <div className="Home_Header">
      <div className="Inner_Container">
        <Link to="/" >
          <img src={logo} alt="avatar" />
        </Link>
        <AiOutlineMenu className="MenuIcon" />
        <div className="Header_Link_Contain" >
          <Link to="/" className="Header_Link" >
            HOME
          </Link>
          <Link to="/get-rates" className="Header_Link" >
            OUR RATES
          </Link>
          <Link to="/track-ship" className="Header_Link" >
            TRACK
          </Link>
          <Link to="/contact-us" className="Header_Link" >
            CONTACT US
          </Link>
          <Link to="/login" className="Start_Btn" >
            <Button color="#E92D38" text="GET STARTED" width="8.69rem" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
