import { Fragment } from "react";
import "../../Assets/Style/Wallet.scss"
import {Top } from "./Components"

const Payments = () => {
    return (
        <Fragment>
         <Top/>
        </Fragment>
    );
}
 
export default Payments;