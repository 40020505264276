import Button from "../../../components/Button";
import logo from "../../../Assets/Img/zeelogo.png"
import { Link } from "react-router-dom";

const RegisterHeader = () => {
    return (
        <div className="Login_Header">
        <img src={logo} alt="avatar" />
       <Link to="/login"><Button color="#225AA4" text="Login" width="107px"/></Link>
    </div>
    );
}
 
export default RegisterHeader;