import React, { useEffect, useState } from "react";
import "../Assets/Style/Inputs.scss";

export const Textbox = ({ value, onChange, disabled = false, name, label="", type="text", placeholder="" }) => {
  return (
    <div className="TextBox">
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};

export const TextArea = ({ value, onChange, disabled = false, name, label="", type="text",rows=10 }) => {
  return (
    <div className="TextBox">
      <label htmlFor={name}>{label}</label>
      <textarea
        type={type}
        name={name}
        onChange={onChange}
        rows={rows}
        value={value}
        disabled={disabled}
      ></textarea>
    </div>
  );
};

export const SelectInput = ({ options, name, handleChange, placeholder="", value="", label="" }) => {
  const [query, setQuery] = useState(value);
  const [showDropdown, setShowDropdown] = useState(false);
  
  useEffect(() => {
    if (query !== value) {
      setQuery(value);
    }
    // eslint-disable-next-line
  }, [value]);
  const inputChange = (value) => {
    handleChange(name, value);
    setQuery(value);
    setShowDropdown(false);
  };
  return (
    <div style={{width:"100%", position: "relative"}} className="">
      {showDropdown && (
        <div
          className={`selectBackdrop`}
          onClick={() => setShowDropdown(false)}
        ></div>
      )}
      <div className={`selectInput TextBox`}>
        <label htmlFor={name}>{label}</label>
        <input
          type="text"
          placeholder={placeholder}
          onFocus={() => setShowDropdown(true)}
          name={name}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      {showDropdown && (
        <div
          className={`selectDropdown`}
        >
          <ul className="selectList" >
            {options?.map(
              (item, id) =>
                item?.label?.toUpperCase()?.includes(query.toUpperCase()) && (
                  <li
                    key={id}
                    style={{ cursor: "default" }}
                    onClick={() => inputChange(item?.value)}
                    className={`${options?.length !== id+1 && "border-b"}`}
                  >
                    {item?.label}
                  </li>
                )
            )}
          </ul>
        </div>
      )}
    </div>
  )
};
