import "../../../Assets/Style/TableNav.scss"

const ShipmentNavigateButton = ({CurrentPage,setCurrentPage,output}) => {


    const Prev=()=>{
        setCurrentPage(pre=>pre-1)
    }
    const Next=()=>{
        console.log("booler"+(CurrentPage===output.length))
        console.log("length"+output.length)
        // console.log("output"+JSON.stringify(output))
        setCurrentPage(pre=>pre+1)
    }

    return (
        <div className="Shimpent_Navigate">
            <button disabled={CurrentPage===1 ? true : false} onClick={Prev} className="Btn">
            <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6866 10.3273C6.29608 10.7178 5.66291 10.7178 5.27239 10.3273L1.27239 6.32729C0.881861 5.93676 0.881861 5.3036 1.27239 4.91307L5.27239 0.913072C5.66291 0.522547 6.29608 0.522547 6.6866 0.913072C7.07712 1.3036 7.07712 1.93676 6.6866 2.32729L4.39371 4.62018L15.9795 4.62018C16.5318 4.62018 16.9795 5.06789 16.9795 5.62018C16.9795 6.17246 16.5318 6.62018 15.9795 6.62018L4.39371 6.62018L6.6866 8.91307C7.07712 9.3036 7.07712 9.93676 6.6866 10.3273Z" fill="#0F172A"/>
            </svg>
            </button>
            <button disabled={output.length<CurrentPage ? true : false} onClick={Next} className="Btn">
            <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5175 0.362046C10.908 -0.0284782 11.5412 -0.0284783 11.9317 0.362046L15.9317 4.36205C16.3222 4.75257 16.3222 5.38574 15.9317 5.77626L11.9317 9.77626C11.5412 10.1668 10.908 10.1668 10.5175 9.77626C10.127 9.38574 10.127 8.75257 10.5175 8.36205L12.8104 6.06915H1.22461C0.672325 6.06915 0.224609 5.62144 0.224609 5.06915C0.224609 4.51687 0.672325 4.06915 1.22461 4.06915H12.8104L10.5175 1.77626C10.127 1.38574 10.127 0.75257 10.5175 0.362046Z" fill="#0F172A"/>
            </svg>
            </button>
        </div>
    );
}
 
export default ShipmentNavigateButton;