import {SessionManagement} from "./SessionManagement";

export function generateTransactionID(length = 15) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  result = result + "+" + SessionManagement.getUserID() + "+" + "44" + "+" + "Web" + "+" + "Time";
  return result;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function UserProfileData() {
  const userID = SessionManagement.getUserID();
  const companyID = SessionManagement.getCompanyID();
  const sessionID = SessionManagement.getSessionID();
  const transactionID = generateTransactionID();

  return {
    user_id: userID,
    company_id: companyID,
    user_ses_id: sessionID,
    transaction_id: transactionID,
    mode: "web",
  };
}
