import { createSlice } from '@reduxjs/toolkit';

// ==============================|| REDUX - MAIN STORE ||============================== //

const LocalShipment=createSlice({
    name:'LocalShipment',
    initialState:{
       Auth:false,
       isLoading:false,
       isBook:false,
       PriceDetails:'',
       PaymentOption:'',
       Locations:'',
       SenderAndReceiverDetails:'',
       ResetStatus:false,
       ResetCode:'',
    },
    reducers:{
        GetResetCode:(state,action)=>{
        state.ResetCode=action.payload
        },
        GetAuth:(state,action)=>{
        state.Auth=action.payload
        },
        GetResetStatus:(state,action)=>{
        state.ResetStatus=action.payload
        },
        GetPriceDetails:(state,action)=>{
        state.PriceDetails=action.payload
        },
        GetFullDetails:(state,action)=>{
        state.SenderAndReceiverDetails=action.payload
        },
        Loading:(state,{payload})=>{
        state.isLoading=payload
        },
        ShipmentStatus:(state,{payload})=>{
        state.isBook=payload
        },
        GetPayment:(state,action)=>{
        state.PaymentOption=action.payload
        },
        GetLocation(state,action){
            state.Locations=action.payload
        },
        EditPackage(state,action){
            state.Package=state.Package
            .map((item)=>{
                if(item.id===action.payload.id){
                    item.name=action.payload.name
                    item.value=action.payload.value
                    item.description=action.payload.description
                    item.weight=action.payload.weight
                    item.quantity=action.payload.quantity
                    item.currency=action.payload.currency
                }
                return item
            })
        },
    }
});

export const LocalShipmentActions = LocalShipment.actions

export default LocalShipment.reducer

