import {Routes, Route} from "react-router-dom";
import MainPageRoute from "./MainPage";
import DashboardPages from "../Pages/Dashboard/DashboardPage";
import RegisterPage from "../Pages/Register/RegisterPage";
import LoginPage from "../Pages/Login/LoginPage";
import CreateLocalShipment from "../Pages/CreateLocalShipment/CreateLocalShipment";
import WrongRoutePage from "../Pages/404Page/WrongRoute";
import ResetPWDPage from "../Pages/ResetPWD/ResetPage";
import Wallet from "../Pages/Payments/Payment"
import Shipments from "../Pages/Shipments/Shipments"
import SettingPage from "../Pages/Setting/Setting";
import CreateInterstateShipment from "../Pages/CreateInterstateShipment/CreateInterstateShipment";
import CreateInternationalShipment from "../Pages/CreateInternationalShipment/CreateInternationalShipment";
import HomePages from "../Pages/Home/HomePage";
import TrackShipment from "../Pages/TrackShipment/TrackShipment";
import HomePage from "../Pages/Homepage";
import Contact from "../Pages/Contact";
import GetRates from "../Pages/GetRates";
import TrackShip from "../Pages/TrackShip";
import CreateShipment from "../Pages/CreateShipment/CreateShipment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const AppRoutes = () => {
    return (
        <Routes>
            {/* <Route exact path="/" element={<HomePages/>}/> */}
            <Route exact path="/" element={<HomePage/>}/>
            <Route exact path="/contact-us" element={<Contact/>}/>
            <Route exact path="/get-rates" element={<GetRates/>}/>
            <Route exact path="/track-ship" element={<TrackShip/>}/>
            <Route exact path="/login" element={<LoginPage/>}/>
            <Route exact path="/register" element={<RegisterPage/>}/>
            <Route exact path="/reset" element={<ResetPWDPage/>}/>
            <Route element={<MainPageRoute/>}>
                <Route exact path="/dashboard" element={<DashboardPages/>}/>
                <Route exact path="/shipments" element={<Shipments/>}/>
                <Route exact path="/setting" element={<SettingPage/>}/>
                <Route exact path="/payments" element={<Wallet/>}/>
                <Route exact path="/create-shipment" element={<CreateShipment/>}/>
                <Route exact path="/create-local-shipment" element={<CreateLocalShipment/>}/>
                <Route exact path="/create-interstate-shipment" element={<CreateInterstateShipment/>}/>
                <Route exact path="/create-international-shipment" element={<CreateInternationalShipment/>}/>
                <Route exact path="/track-shipment" element={<TrackShipment/>}/>
            </Route>
            <Route path="*" element={< WrongRoutePage/>}/>
        </Routes>
    );
}

export default AppRoutes;