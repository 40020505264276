import "../../../Assets/Style/PaymentMethod.scss"
import AOS from "aos";
import "aos/dist/aos.css";
import {useEffect, useState} from "react";
import {getWalletBalanceURL} from "../../../Library/URLs";
import axios from "axios";
import {numberWithCommas, UserProfileData} from "../../../Library/Utilities";
import { useDispatch, useSelector } from "react-redux";
import { LocalShipmentActions } from "../../../store/Local_Slice";

const PaymentMethod = ({setTab}) => {

    const Paymethod = useSelector((state) => state.Local.PaymentOption)
    const dispatch = useDispatch()

    const [isRetrievingBalance, setIsRetrievingBalance] = useState(true);
    const [walletBalance, setWalletBalance] = useState("0.00");

    const choosePaymentMethod = () => {
        setTab(pre => pre + 1);
    }

    useEffect(() => {
        AOS.init({
          duration : 2000
        });

        axios
            .post(getWalletBalanceURL, UserProfileData(), {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsRetrievingBalance(false);

                if (response["data"]["success"] === false) return;

                const balance = response["data"]["data"]["account_balance"];
                setWalletBalance(numberWithCommas(balance));
            });
      }, []);

    return (
        <div data-aos="fade-zoom-in" className="Payment_Method">
            <h3>AVAILABLE PAYMENT MENTHOD</h3>
            <div className="Box">
        <div className="Detail">
          <h4>Debit card</h4>
          <h5>Pay using Mastercard, Visa or Verve card</h5>
        </div>
        <input
          value="Card Payment"
          onChange={(e) =>
            dispatch(LocalShipmentActions.GetPayment(e.target.value))
          }
          name="radio"
          type="radio"
          checked={Paymethod === 'Card Payment' ? true : false}
        />
      </div>
      <div className="Box">
        <div className="Detail">
          <h4>Virtual bank transfer</h4>
          <h5>Pay by sending funds to a virtual bank account</h5>
        </div>
        <input
          value="Direct Transfer"
          onChange={(e) =>
            dispatch(LocalShipmentActions.GetPayment(e.target.value))
          }
          name="radio"
          type="radio"
          checked={Paymethod === 'Direct Transfer' ? true : false}
        />
      </div>
      <div className="Box">
        <div className="Detail">
          <h4>Wallet</h4>
          <h5>Pay by sending funds to a virtual bank account</h5>
        </div>
        <input
          value="Wallet"
          onChange={(e) =>
            dispatch(LocalShipmentActions.GetPayment(e.target.value))
          }
          name="radio"
          type="radio"
          checked={Paymethod === 'Wallet' ? true : false}
        />
      </div>
            <div className="Balance">
                <h2>{isRetrievingBalance ? "..." : walletBalance} <span>NGN</span> </h2>
                <h6>Insufficient balance</h6>
            </div>
            <small>By continuing, I represent that the declaration above is a proper and accurate description of the contents of my package.</small>
            <button onClick={choosePaymentMethod}>CONTINUE</button>
        </div>
    );
}
 
export default PaymentMethod;