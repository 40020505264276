import axios from 'axios'
import { InterStateActions } from './InterState_Slice'
import { toast } from 'react-toastify';
import {
  calculateInterStateCostURL,
  bookInterstateShipment,
} from '../Library/URLs'
import { generateTransactionID } from '../Library/Utilities'

export const CostData = (Sender, Receiver) => {
  const user_id = localStorage.getItem('user_id')
const user_ses_id = localStorage.getItem('session_id')
const company_id = localStorage.getItem('company_id')
const transaction_id = generateTransactionID(15)
  return async (dispatch) => {
    dispatch(InterStateActions.Loading(true))
    const CalCost = async () => {
      let data
      const raw = {
        user_id: user_id,
        user_type: 'Regular',
        user_ses_id: user_ses_id,
        company_id: company_id,
        transaction_id: transaction_id,
        weight: Sender.weight,
        processor: 'DHL',
        sender_address: Sender.address_one,
        sender_country: 'Nigeria',
        sender_city: Sender.city,
        sender_state: Sender.state,
        sender_zip_code: '101001',
        sender_phone: Sender.mobile,
        receiver_address: Receiver.address_one,
        receiver_country: 'Nigeria',
        receiver_city: Receiver.city,
        receiver_state: Receiver.state,
        receiver_zip_code: '202002',
        receiver_phone_number: Receiver.mobile,
        type: 'Interstate',
        request_pickup: 'No',
      }
      console.log('calculate cost postdata' + JSON.stringify(raw))
      const res = await axios.post(calculateInterStateCostURL, raw) // returns promise
      data = res.data
      return data
    }
    try {
      const interstateCost = await CalCost()

      if (interstateCost.success === false) {
        dispatch(InterStateActions.TogglePrice(false))
        toast.error(interstateCost.message)
      }
      if (interstateCost.success === true) {
        dispatch(InterStateActions.TogglePrice(true))
        dispatch(InterStateActions.GetPriceDetails(interstateCost.data))
      }
      dispatch(InterStateActions.Loading(false))
      console.log('calculate cost error' + JSON.stringify(interstateCost))
    } catch (error) {
      // console.log('calculate cost error' + error.message)
      // toast.error('calculate cost error' + error.message)
      dispatch(InterStateActions.Loading(false))
    }
  }
}

export const BookInterStateShipment = (Data) => {
  return async (dispatch) => {
    dispatch(InterStateActions.Loading(true))
    console.log('book', Data)
    const BookShipment = async () => {
      let data

      const res = await axios.post(bookInterstateShipment, Data) // returns promise
      data = res.data
      return data
    }
    try {
      const BookInterState = await BookShipment()
      console.log('book', JSON.stringify(BookInterState))
      if (BookInterState.success === false) {
        dispatch(InterStateActions.ShipmentStatus(false))
        toast.error(BookInterState.message)
      }
      if (BookInterState.success === true) {
        dispatch(InterStateActions.ShipmentStatus(true))
        toast.success('Shipment as been successfully book')
      }
      dispatch(InterStateActions.Loading(false))
    } catch (error) {
      console.log('Shipment Book error' + error.message)
      toast.error('Shipment Book error' + error.message)
      dispatch(InterStateActions.Loading(false))
    }
  }
}

export const ClearRecord = () => {
  return async (dispatch) => {
    dispatch(InterStateActions.ShipmentStatus(false))
    dispatch(InterStateActions.GetOverall(''))
    dispatch(InterStateActions.GetOverall(''))
    dispatch(InterStateActions.GetPriceDetails(''))
    dispatch(InterStateActions.GetPayment(''))
    dispatch(InterStateActions.GetSender(''))
    dispatch(InterStateActions.GetReceiver(''))
    dispatch(InterStateActions.ResetPackage([]))
    dispatch(InterStateActions.GetRequestPack(''))
    dispatch(InterStateActions.ResetTotalQuantity(0))
    dispatch(InterStateActions.ResetTotalValue(0))
    dispatch(InterStateActions.ResetTotalWeight(0))
  }
}
