const DataTwo = () => {
    return (
        <table>
            <thead>
                <th>DROP OFF ADDRESS</th>
                <th>RECEIVER NAME</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
                <th>STATUS</th>
                </thead>
                <tbody>
                <tr>
                    <td colSpan={5}>
                        <div className="No_Data">
                        <h5>No Shipment record</h5>
                        </div>
                    </td>
                </tr>
            </tbody>
            </table>
    );
}
 
export default DataTwo;