import React, { useState } from "react";
import { TextArea, Textbox } from "../../../components/FormInputs";
import Button from "../../../components/Button";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phoneNumber: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <div className="ContactForm">
      <div>
        <h1 className="InfoHero">We'd love to hear from you</h1>
        <p className="InfoSub">
        Can't visit any of our centers? Fill in the form below and one of customer support team will reach out to you
        </p>
        <div className="FormContain">
          <div className="FormGrid">
            <Textbox
              name="first_name"
              label="First Name"
              value={formData.first_name}
              onChange={handleChange}
            />
            <Textbox
              name="last_name"
              label="Last Name"
              value={formData.last_name}
              onChange={handleChange}
            />
          </div>
          <div className="FormGrid">
            <Textbox
              name="phoneNumber"
              label="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
              type="tel"
            />
            <Textbox
              name="email"
              label="Email Address"
              value={formData.email}
              onChange={handleChange}
              type="email"
            />
          </div>
          <div className="TextAreaContain">
            <TextArea
              name={"message"}
              value={formData.message}
              label="Message"
              onChange={handleChange}
            />
          </div>
          <div className="BtnWrap">
            <Button text="SEND MESSAGE" color={"#E92D38"} width={"100%"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
