import blue_eye from "../../Assets/Img/blue_eye.png"
import wallet from "../../Assets/Img/wallet.png"
import "../../Assets/Style/Dashboard.scss"
import DashboardCards from "./components/DashboardCard";
import {useEffect, useState} from "react";
import AOS from "aos";
import {LocalShipmentDataStorage} from "../../Library/LocalShipmentDataStorage";
import axios from "axios";
import {getShipmentsURL} from "../../Library/URLs";
import {generateTransactionID} from "../../Library/Utilities";
import { NumericFormat } from 'react-number-format';
import { Link } from "react-router-dom";

const CreateShipment = () => {
    const [Result,setResult]=useState('');
    const name=localStorage.getItem('name')
    
    useEffect(()=>{
   const GetShipments=async()=>{
    const Data={
        "user_id":localStorage.getItem('user_id'),
        "user_ses_id":localStorage.getItem('session_id'),
        "company_id":localStorage.getItem('company_id'),
        "transaction_id":generateTransactionID(15),
    }
        await axios
        .post(getShipmentsURL,Data)
        .then(response=>{
            const res=response.data.data
            setResult(res)
        })
    }

    GetShipments()
    },[])

    return (
    <div className="Dashboard_Wrapper">
        <div className="CreateBanner">
            <div className="Inner_Card">
                <div className="Top_Box">
                    <div className="Left">
                        <h4>Number of Shipments</h4>
                        <div className="Wrap">
                        <h2>{Result?.length ?? 0}</h2>
                        </div>
                    </div>
                    <Link to="/shipments" className="viewLink">
                        View All
                    </Link>
                </div>
            </div>
        </div>
        <DashboardCards/>
    </div>
    );
}
 
export default CreateShipment;