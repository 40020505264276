import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../Assets/Img/zeelogo.png"
import "../../Assets/Style/Dashboard_Header.scss"
import data from "./components/links"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { MdMenu } from "react-icons/md";
import { Nav, Offcanvas } from "react-bootstrap";

const style = {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '16px',
    marginLeft: 1,
};


const GeneralHeader = () => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSidebar, setOpenSidebar] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogOut = () => {
        localStorage.removeItem('is_authentication')
        localStorage.clear()
        navigate("/login")
        setAnchorEl(null);
    };

    // Control mobile sidebar
    const handleSidebarClose = () => setOpenSidebar(false);
    const handleSidebarShow = () => setOpenSidebar(true);

    return (
        <div className="General_Header">
            <div className="Inner_Container">
                <Link to="/dashboard"><img src={logo} alt="avatar" /></Link>
                <div className="Right">
                    {
                        data.map((item, index) => <NavLink key={index} to={item.link} className={({ isActive }) => (isActive ? "active-link" : "")}>{item.name}</NavLink>)
                    }
                    <span
                        onClick={handleClick}
                        className="Account_Btn"
                    >
                        Account <KeyboardArrowDownIcon />
                    </span>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {/* <MenuItem onClick={handleClose}>
                <PersonIcon /><Typography sx={style}>Account</Typography>
                </MenuItem> */}
                        <MenuItem component={Link} to="/setting" className="dropdown_link" onClick={handleClose}>
                            <SettingsIcon /> <Typography sx={style}>Setting</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleLogOut}>
                            <ExitToAppIcon /> <Typography sx={style}>LogOut</Typography>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            <div className="Inner_Container_mobile">
                <Link to="/dashboard"><img src={logo} alt="avatar" /></Link>
                <MdMenu className="menuIcon" onClick={handleSidebarShow} />
                <Offcanvas show={openSidebar} onHide={handleSidebarClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <Link to="/dashboard" onClick={handleClose}><img src={logo} alt="avatar" /></Link>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="flex-column">
                            <Nav.Link style={{color:"#000000"}} href="/create-shipment">Create Shipment</Nav.Link>
                            <Nav.Link style={{color:"#000000"}} href="/shipments">Shipment History</Nav.Link>
                            <Nav.Link style={{color:"#000000"}} href="/track-shipment">Track Shipment</Nav.Link>
                            <Nav.Link style={{color:"#000000"}} href="/payments">Wallet</Nav.Link>
                            <Nav.Link style={{color:"#000000"}} href="/setting">Settings</Nav.Link>
                            <Nav.Link style={{color:"#000000"}} onClick={handleLogOut}>Logout</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </div>
    );
}

export default GeneralHeader;