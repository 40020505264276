import DataOne from "./Shipment_Table_One";
import { useEffect, useState } from "react";
import axios from "axios";
import { getShipmentsURL } from "../../../Library/URLs";
import { generateTransactionID } from "../../../Library/Utilities";
import DataTwo from "./Shipment_Table_Two";
import { Backdrop, Box, Fade, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Moment from "react-moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  outerWidth: "0px",
  borderRadius: "5px",
  py: 4,
  px: 2,
};

const ShipmentTable = () => {
  const [Result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const handleClose = () => {
    setSelectedShipment(null);
  };

  const render = (res) => {
    switch (res) {
      case "Pending Pickup":
        return "Pending";
        break;
      case "Cancelled":
        return "Cancel";
        break;
      case "Rejected":
        return "Rejected";
        break;
      case "Delivered":
        return "Delivered";
        break;
      case "Pending Approval":
        return "Pending Approval";
        break;

      default:
        return "Success";
        break;
    }
  };

  const renderColor = (res) => {
    switch (res) {
      case "Pending Pickup":
        return "pending";
        break;
      case "Cancelled":
        return "cancel";
        break;
      case "Rejected":
        return "cancel";
        break;
      case "Delivered":
        return "success";
        break;
      case "Pending Approval":
        return "pending";
        break;

      default:
        return "success";
        break;
    }
  };

  useEffect(() => {
    const GetShipmentHistory = async () => {
      setLoading(true);
      const Data = {
        user_id: localStorage.getItem("user_id"),
        user_ses_id: localStorage.getItem("session_id"),
        company_id: localStorage.getItem("company_id"),
        transaction_id: generateTransactionID(15),
        limit: "10",
        offset: "0",
      };
      await axios
        .post(getShipmentsURL, Data)
        .then((response) => {
          const res = response.data.data;
          setLoading(false);
          setResult(Object.values(res));
        })
        .catch(() => {
          setLoading(false);
        });
    };

    GetShipmentHistory();
  }, []);

  return (
    <div className="Shipment_Table_Wrapper">
      <h4>All Shipments</h4>
      {Result !== "" && !loading && (
        <DataOne data={Result} setSelectedShipment={setSelectedShipment} />
      )}
      {Result === "" && !loading && <DataTwo />}
      {loading && (
        <div className="SpinContain">
          <span className="spinner-border spinner-grow-md" />
        </div>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={selectedShipment !== null}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={selectedShipment !== null}>
          <Box sx={style} className="Rate_Modal">
            <div className="Top_section d-flex justify-content-between align-items-center">
              <h4>View Shipment</h4>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="ShipmentInfo">
              <div className="ShipmentSection SectionDivide">
                <p className="ShipmentLabel">SENDER DETAILS</p>
                <p>{selectedShipment?.sender_name} </p>
                <p>{selectedShipment?.sender_phone_no} </p>
                <p>{selectedShipment?.pick_up_location} </p>
                <p>
                  {selectedShipment?.recipient_state},{" "}
                  {selectedShipment?.sender_city}{" "}
                </p>
                <p>{selectedShipment?.sender_country} </p>
                <p>{selectedShipment?.sender_postcode} </p>
              </div>
              <div className="ShipmentSection SectionDivide">
                <p className="ShipmentLabel">RECEIVER DETAILS</p>
                <p>{selectedShipment?.recipient_name} </p>
                <p>{selectedShipment?.recipient_phone_no} </p>
                <p>{selectedShipment?.drop_off_location} </p>
                <p>
                  {selectedShipment?.recipient_state},{" "}
                  {selectedShipment?.recipient_city}{" "}
                </p>
                <p>{selectedShipment?.recipient_country} </p>
                <p>{selectedShipment?.recipient_postcode} </p>
              </div>
              <div className="ShipmentSection pe-3">
                <p className="ShipmentLabel mb-2">SHIPMENT DETAILS</p>
                <div className="ShipmentInfo">
                  <div>
                    <p className="InfoLabel">Description</p>
                    <p>{selectedShipment?.item_name} </p>
                  </div>
                  <div className="ShipRight">
                    <p className="InfoLabel">Shipment No.</p>
                    <p>{selectedShipment?.shipment_no} </p>
                  </div>
                  <div>
                    <p className="InfoLabel">Payment Method</p>
                    <p>{selectedShipment?.payment_method} </p>
                  </div>
                  <div className="ShipRight">
                    <p className="InfoLabel">Amount</p>
                    <p>{selectedShipment?.total_amount} </p>
                  </div>
                  <div>
                    <p className="InfoLabel">Booking Date</p>
                    <p>
                      <Moment format="MMM DD YYYY, h:mm a">
                        {selectedShipment?.date_time}
                      </Moment>
                    </p>
                  </div>
                  <div className="">
                    <p className="InfoLabel text-end">Status</p>
                    <div className="d-flex justify-content-end">
                      <div
                        className={`BtnShip ${renderColor(
                          selectedShipment?.status
                        )}`}
                      >
                        <span style={{ fontSize: 10 }}>
                          {render(selectedShipment?.status)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ShipmentTable;
