
export class LocalShipmentDataStorage {
  static setPickupLocation(pickupLocation) {
    return localStorage.setItem("pickupLocation", pickupLocation);
  }

  static getPickupLocation() {
    return localStorage.getItem("pickupLocation");
  }

  static setDropoffLocation(dropoffLocation) {
    return localStorage.setItem("dropoffLocation", dropoffLocation);
  }

  static getDropoffLocation() {
    return localStorage.getItem("dropoffLocation");
  }

  static setSenderName(senderName) {
    return localStorage.setItem("senderName", senderName);
  }

  static getSenderName() {
    return localStorage.getItem("senderName");
  }

  static setSenderNumber(senderNumber) {
    return localStorage.setItem("senderNumber", senderNumber);
  }

  static getSenderNumber() {
    return localStorage.getItem("senderNumber");
  }

  static setReceiverName(receiverName) {
    console.log("Setting receiver name");
    return localStorage.setItem("receiverName", receiverName);
  }

  static getReceiverName() {
    return localStorage.getItem("receiverName");
  }

  static setReceiverNumber(receiverNumber) {
    return localStorage.setItem("receiverNumber", receiverNumber);
  }

  static getReceiverNumber() {
    return localStorage.getItem("receiverNumber");
  }

  static setPackageDetails(packageDetails) {
    return localStorage.setItem("packageDetails", packageDetails);
  }

  static getPackageDetails() {
    return localStorage.getItem("packageDetails");
  }


  static clearValues() {
    localStorage.setItem("pickupLocation", "");
    localStorage.setItem("dropoffLocation", "");

    localStorage.setItem("senderName", "");
    localStorage.setItem("senderNumber", "");
    localStorage.setItem("receiverName", "");
    localStorage.setItem("receiverNumber", "");
    localStorage.setItem("packageDetails", "");
  }
}
