import React from "react";
import "../../../Assets/Style/Partners.scss";
import ZLogo from "../../../Assets/Img/zeeCircle.png";
import Aramex from "../../../Assets/Img/aramex2.png";
import DHL from "../../../Assets/Img/DHL2.png";
import Global from "../../../Assets/Img/global.png";
import Fedex from "../../../Assets/Img/fedex2.png";
import Ups from "../../../Assets/Img/ups2.png";

const Partners = () => {
  return (
    <div className="PartnerSection">
      <h2 className="Title">Mutiple Shipping Partners</h2>
      <p className="SubTitle">
        With over 20+ shipping partners across to globe to deliver your packages
        safely.
      </p>
      <div className="Sponsors">
        <div className="LogoContain">
          <img src={ZLogo} alt="logo" />
        </div>
        {/* Row 1 */}
        <div className="PartnerGrid">
          <div className="PartnerContain">
            <img src={DHL} alt="dhl" />
            <div>
              <p className="PartnerName">DHL</p>
              <p className="PartnerInfo">Nationwide & International</p>
            </div>
          </div>
          <div className="PartnerContain">
            <img src={Fedex} alt="fedex" />
            <div>
              <p className="PartnerName">FedEx</p>
              <p className="PartnerInfo">International Only</p>
            </div>
          </div>
        </div>
        {/* Row 2 */}
        <div className="PartnerGrid GridShift">
          <div className="PartnerContain">
            <img src={Ups} alt="ups" />
            <div>
              <p className="PartnerName">UPS</p>
              <p className="PartnerInfo">Nationwide & International</p>
            </div>
          </div>
          <div className="PartnerContain">
            <img src={Aramex} alt="Aramex" />
            <div>
              <p className="PartnerName">Aramex</p>
              <p className="PartnerInfo">International Only</p>
            </div>
          </div>
        </div>
        {/* Row 3 */}
        <div className="PartnerGrid">
          <div className="PartnerContain">
            <img src={Global} alt="global" />
            <div>
              <p className="PartnerName">United Airlines</p>
              <p className="PartnerInfo">Cargo & International</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
