// const domain = "https://users.mylogisticsapp.com/engine/v1/actions/";
// const domain = "https://user.parcelflow.io/engine/v1/actions/";
// const domain = "https://parcelflow-user-backend-7948506154d4.herokuapp.com/engine/v1/actions/";
const domain = "https://parcelflow-user-b4e5130303b2.herokuapp.com/engine/v1/actions/";


export const loginURL = domain + "login";
export const registerURL = domain + "registration";
export const logoutURL = domain + "logout";
export const getAddressSuggestionsURL = domain + "auto-complete-search-new";
export const calculateLocalCostURL = domain + "calculate-shipment-cost-new";
export const calculateInterStateCostURL = domain + "calculate-shipment";
export const createInternationalShipment = domain + "calculate-shipment";
// export const createInterstateShipment = domain + "create-inter-shipment-terminal.php";
export const createInterstateShipment = domain + "create-international-shipment-new";
export const createLocalShipment = domain + "create-shipment";
// export const bookInterstateShipment = domain + "create-inter-shipment-terminal-new";
export const bookInterstateShipment = domain + "create-international-shipment-new";
export const getDashboardStatisticsURL = domain + "get-dashboard-stats";
export const createLocalShipmentURL = domain + "get-transactions";
export const getShipmentsURL = domain + "get-shipments";
export const searchDHLCityURL = domain + "search-dhl-cities";
export const getWalletBalanceURL = domain + "get-wallet";
export const FundWalletBalanceURL = domain + "fund-wallet";
export const RequestPWDURL = domain + "request-reset-password-code";
export const ResetPWDURL = domain + "reset-password";
export const getWalletHistoryURL = domain + "get-wallet-history";
export const trackURL = domain + "track-shipment-by-number";
export const GetAllCities = domain + "get-cities";
export const GetAllStatesUrl = domain + "get-states"
export const getRatesUrl = domain + "get-rates"