import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/Style/LoginPage.scss"
import { LocalShipmentActions } from "../../store/Local_Slice";
import {
    Request,
    Reset
} from "./components"

const ResetPWD = () => {

    const {ResetStatus}=useSelector((state)=>state.Local)
    const dispatch=useDispatch()

    useEffect(()=>{
      dispatch(LocalShipmentActions.GetResetStatus(false))
    },[dispatch])

    return (
        <div className="Login_Container_Fill">
            <div className="Left"></div>
            <div className="Right">
               {ResetStatus===true && <Reset/>}
               {ResetStatus===false && <Request/>}
            </div>
            <div className="Mobile_Phone"></div>
        </div>
    );
}
 
export default ResetPWD;