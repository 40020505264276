import {useForm} from "react-hook-form";
import {useEffect, useState} from 'react';
import {toast } from 'react-toastify';
import AOS from "aos";
import "aos/dist/aos.css";
import {LocalShipmentDataStorage} from "../../../Library/LocalShipmentDataStorage";
import { useDispatch, useSelector } from "react-redux";
import { LocalShipmentActions } from "../../../store/Local_Slice";

const ItemDetailsForm = ({setTab, shipmentFee}) => {
    const {register, reset, handleSubmit, formState: {errors}} = useForm();
    const [isClear, setIsClear] = useState(false);

    const dispatch=useDispatch()
    const Details = useSelector((state) => state.Local.SenderAndReceiverDetails)

    const [senderName, setSenderName] = useState("");
    const [senderNumber, setSenderNumber] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [receiverNumber, setReceiverNumber] = useState("");
    const [item, setItem] = useState("");

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

   
    useEffect(()=>{
        if (!isClear) {
            return
        } else {
            reset()
        }
    },[isClear,reset])

    useEffect(() => {
        if (Details!== '') {
           reset(Details)
        }
        }, [Details, reset])

    const onSubmit = data => {
        if (data){
            dispatch(LocalShipmentActions.GetFullDetails(data))
            setIsClear(true)
            setTab(pre => pre + 1)
        }else setIsClear(false)
    };

    return (
        <form data-aos="fade-zoom-in" onSubmit={handleSubmit(onSubmit)}>
            <div className="Top">
                <div className="Box">
                    <label htmlFor="first">SENDER NAME</label>
                    <input id="first" placeholder="Sender name" value={senderName}
                           className={`${errors.sender_name ? 'error' : 'noError'}`}
                           {...register("sender_name", {required: true, onChange: (e) =>setSenderName(e.target.value) })} />
                    {errors.sender_name &&
                        <span className={`${errors.sender_name ? 'errorText' : ''}`}>Sender name is required</span>}
                </div>
                <div className="Box">
                    <label htmlFor="last">SENDER NUMBER</label>
                    <input id="last" placeholder="Sender Number"  value={senderNumber}
                           className={`${errors.sender_number ? 'error' : 'noError'}`}
                           {...register("sender_number", {required: true, onChange: (e) =>setSenderNumber(e.target.value)})} />
                    {errors.sender_number &&
                        <span className={`${errors.sender_number ? 'errorText' : ''}`}>Sender number is required</span>}
                </div>
            </div>
            <div className="Top">
                <div className="Box">
                    <label htmlFor="receiver_name">RECEIVER NAME</label>
                    <input id="receiver_name" placeholder="Receiver name" value={receiverName}
                           className={`${errors.receiver_name ? 'error' : 'noError'}`}
                           {...register("receiver_name", {required: true, onChange: (e) =>setReceiverName(e.target.value)})} />
                    {errors.receiver_name &&
                        <span className={`${errors.receiver_name ? 'errorText' : ''}`}>Receiver name is required</span>}
                </div>
                <div className="Box">
                    <label htmlFor="receiver_number">RECEIVER NUMBER</label>
                    <input id="receiver_number" placeholder="Receiver number" value={receiverNumber}
                           className={`${errors.receiver_number ? 'error' : 'noError'}`}
                           {...register("receiver_number", {required: true, onChange: (e) =>setReceiverNumber(e.target.value)})} />
                    {errors.receiver_number &&
                        <span className={`${errors.receiver_number ? 'errorText' : ''}`}>Receiver number is required</span>}
                </div>
            </div>
            <div className="Box">
                <label htmlFor="street">ITEM</label>
                <input id="street" placeholder="Item description" value={item}
                       className={`${errors.package ? 'error' : 'noError'}`}
                       {...register("package", {required: true, onChange: (e) =>setItem(e.target.value)})} />
                {errors.package &&
                    <span className={`${errors.package ? 'errorText' : ''}`}>Package is required</span>}
            </div>
            <input type="submit" value="CONTINUE"/>
        </form>
    );
}

export default ItemDetailsForm;