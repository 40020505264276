import "../../../Assets/Style/HomeHeroSection.scss";
import agent from "../../../Assets/Img/deliverBoy.png";
import pack from "../../../Assets/Img/packing.png";
import jobs from "../../../Assets/Img/ZeeJobs.png";
import { useNavigate } from "react-router-dom";
import CurlArrow from "../../../Assets/Img/curlarrow.svg";

const Hero = () => {
  const navigate = useNavigate();

  const SignUp = () => {
    navigate("/register", { replace: true });
  };
  return (
    <div className="Home_Hero_Wrapper">
      <img src={CurlArrow} alt="illustrate" className="arrowIllustrate" />
      <div className="Left">
        {/* <h3>Procurement & Logistics</h3>
            <h3>Service company in Nigeria</h3> */}
        <h3>
          World Class Shipping,
          <br /> For Low Cost Pricing
        </h3>
        <div className="Text_Wrap">
          {/* <p>We help you with local and international deliveries to </p>
            <p>your customers anywhere in the world.</p> */}
          <p>
            Shipping should not be hard. Let Zee Express help you send items to
            your customers, friends, and family in Port-Harcout, Kano, London,
            Ghana, New York, Dubai and 200+ more cities. Say goodbye to
            stressful shipping forever!
          </p>
          {/* <p>Please limit shipping services to 4</p> */}
        </div>
        <div className="BtnContain">
          <button onClick={SignUp} className="Btn BtnHomeCreate">
            START SHIPPING
          </button>
          <button onClick={SignUp} className="Btn BtnContactSales">
            Contact Sales
          </button>
        </div>
      </div>
      <div className="Right">
        <div className="Delivery_Wrapper">
          {/* <div className="Fade_Orange"></div> */}
          {/* <div className="Bg_Color"></div> */}
          <img src={jobs} alt="avatar" className="ImgIllustrate" />
          {/* <div className="Little_Card">
            <p>I would like to send a</p>
            <p>package to UK and Ghana</p>
          </div> */}
          {/* <div className="Badge">
            <div className="wrap">
              <img src={pack} alt="avatar" />
            </div>
          </div> */}
          {/* <div className="First_White_Badge"></div> */}
          {/* <div className="Second_White_Badge"></div> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
