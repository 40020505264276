import { useState } from "react";
import { 
    EmptyTransaction,
    Transaction,
    TopTrans,
    TransTab,
    TransTable} from ".";

const WalletTop = () => {

    return (
        <div className="Wallet_Top">
           <TopTrans/>
           <TransTab/>
           <Transaction/>
        </div>
    );
}
 
export default WalletTop;