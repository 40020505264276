import Benefits from "./components/Benefits"
import Header from "../../components/Header"
import Hero from "./components/Hero"
import Partners from "./components/Partners"
import Plugin from "./components/Plugin"
import HomeFooter from "../../components/Footer"

const HomePage = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Benefits />
      <Partners />
      <Plugin />
      <HomeFooter />
    </div>
  )
}

export default HomePage