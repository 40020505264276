import "../../Assets/Style/LoginPage.scss"
import FormSection from "./components/FormSection";
import LoginHeader from "./components/LoginHeader";


const LoginPage = () => {
    return (
        <div className="Login_Container_Fill">
            <div className="Left"></div>
            <div className="Right">
                <LoginHeader/>
                <FormSection/>
            </div>
            <div className="Mobile_Phone"></div>
        </div>
    );
}

export default LoginPage;