import { Fragment } from "react";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {FundWalletBalanceURL} from "../../../Library/URLs";
import { generateTransactionID } from "../../../Library/Utilities";
import axios from "axios";
// import AOS from "aos";
// import "aos/dist/aos.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline: 'none',
    outerWidth: '0px',
    borderRadius: '5px',
    p:4,
};

const TransTop = () => {

    const [Tab,setTab]=useState(1)
    const [Amount,setAmount]=useState()

    const [SMS,setSMS]=useState()

    const [isLoading,setisLoading]=useState(false)


    const [open, setOpen] = useState(false);
    const handleOpen = () =>{
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const FundWallet=async()=>{
        setisLoading(true)
        const data={
            "user_id":localStorage.getItem('user_id'),
            "user_ses_id":localStorage.getItem('session_id'),
            "company_id":localStorage.getItem('company_id'),
            "transaction_id":generateTransactionID(15),
            "amount_paid": Amount,
            "transaction_reference_no":"",
            "payment_method":`${Tab ===1? 'Direct Transfer':'Card Payment'}`
        }
        await axios
        .post(FundWalletBalanceURL,data)
        .then(res=>{
            if(res.data.success===false){
                console.log("res"+JSON.stringify(res))
                setSMS(res.data.message)
            }
            if(res.data.success===true){
                console.log("res"+JSON.stringify(res))
                handleClose()
            }
        })
        setisLoading(false)
    }


    return (
       <Fragment>
         <div className="Top">
        <h2>Wallet</h2>
        <div className="Button_Box">
            <div className="Btn">WITHDRAW</div>
            <div onClick={handleOpen} className="Btn Danger">FUND WALLET</div>
        </div>
        </div>
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="Modal_Wrapper">
                        <span onClick={handleClose} className="close_btn"><CloseIcon/></span>
                        <div className="Tab_Wrapper">
                            <div onClick={()=>setTab(1)} className={`Tab ${Tab===1 ? '':'active_tab'}`}>DIRECT TRANSFER</div>
                            <div onClick={()=>setTab(2)} className={`Tab ${Tab===2 ? '':'active_tab'}`}>CARD PAYMENT</div>
                        </div>
                       {Tab===2 &&  <div className="Details_Wrapper Amount">
                        {SMS && <label className="error">{SMS}</label>}
                        <label htmlFor="amount">Amount</label>
                        <input type="tel" value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder="0.00" />
                        <div onClick={FundWallet} className="Btn">{isLoading===true ? 
                        <span className="spinner-border spinner-grow-sm"/> : 
                        'PAY'}</div>
                        </div>}
                        {Tab===1 &&  <div className="Details_Wrapper">
                          <div className="Top">
                            <div className="Box">
                                <p>Account Name</p>
                                <h5>Oluwaseyi Akingbade</h5>
                            </div>
                            </div>
                            <div className="Bottom">
                                <div className="Box">
                                    <p>Account Number</p>
                                    <h5>0108874795</h5>
                                </div>
                                <div className="Box">
                                    <p>Bank Name</p>
                                    <h5>Guarantee Trust Bank</h5>                                 
                                </div>
                            </div> 
                        </div>}
                    </div>
                </Box>
            </Fade>
        </Modal>
       </Fragment>
      
    );
}
 
export default TransTop;