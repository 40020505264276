import { Fragment } from "react";

const ShipmentTop = () => {
    return (
        <Fragment>
            <div className="Shipment_Top">
                <h3>Shipments</h3>
                <div className="Top w-100">
                    <div className="Select_Box w-100 align-items-start align-items-md-center flex-column flex-md-row">
                        <select name="" className=" " id="">
                            <option selected>Filter By</option>
                        </select>
                        <select name="" id="" className="" >
                            <option selected>Choose date</option>
                        </select>
                        <div className="Search_Box" >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70566 1.95955C3.71744 1.95955 2.10566 3.57132 2.10566 5.55955C2.10566 7.54777 3.71744 9.15955 5.70566 9.15955C7.69389 9.15955 9.30566 7.54777 9.30566 5.55955C9.30566 3.57132 7.69389 1.95955 5.70566 1.95955ZM0.305664 5.55955C0.305664 2.57721 2.72333 0.159546 5.70566 0.159546C8.688 0.159546 11.1057 2.57721 11.1057 5.55955C11.1057 6.72579 10.736 7.80569 10.1073 8.68843L14.4421 13.0231C14.7935 13.3746 14.7935 13.9445 14.4421 14.2959C14.0906 14.6474 13.5207 14.6474 13.1693 14.2959L8.83455 9.96122C7.95181 10.5898 6.87191 10.9595 5.70566 10.9595C2.72333 10.9595 0.305664 8.54188 0.305664 5.55955Z" fill="white"/>
                        </svg>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default ShipmentTop;