import { useEffect, useState } from "react";
import Moment from "react-moment";
import axios from "axios";
import { getWalletHistoryURL } from "../../../Library/URLs";
import { generateTransactionID } from "../../../Library/Utilities";
import EmptyWallet from "./Empty_Transaction";
import TransTable from "./Trans_Table";
import ShipmentNavigateButton from "../../Shipments/Components/Shipment_Navigate";

const Transaction = () => {
  const [Results, setResults] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [PostPerPage, setPostPerPage] = useState(20);
  const LastPost = CurrentPage * PostPerPage;
  const FirstPost = LastPost - PostPerPage;
  const Output = Results.slice(FirstPost, LastPost);
  const Length = Results.length - 1;

  useEffect(() => {
    const GetShipmentHistory = async () => {
        setLoading(true);
      const Data = {
        user_id: localStorage.getItem("user_id"),
        user_ses_id: localStorage.getItem("session_id"),
        company_id: localStorage.getItem("company_id"),
        transaction_id: generateTransactionID(15),
        limit: "10",
        offset: "0",
      };
      await axios.post(getWalletHistoryURL, Data).then((response) => {
        const res = Object.values(response.data.data);
        setLoading(false);
        setResults(res);
      })
      .catch(() => {setLoading(false)});
    };
    GetShipmentHistory();
    console.log(Results);
  }, []);

  const render = (res) => {
    switch (res) {
      case "Pending Pickup":
        return "Pending";
        break;
      case "Cancelled":
        return "Cancel";
        break;
      case "Rejected":
        return "Rejected";
        break;
      case "Delivered":
        return "Delivered";
        break;
      case "Pending Approval":
        return "Pending Approval";
        break;

      default:
        return "Success";
        break;
    }
  };
  const renderColor = (res) => {
    switch (res) {
      case "Pending Pickup":
        return "pending";
      case "Cancelled":
        return "cancel";
      case "Rejected":
        return "cancel";
      case "Delivered":
        return "success";
      case "Pending Approval":
        return "pending";
      case "credit":
        return "success";
      case "debit":
        return "cancel";

      default:
        return "success";
    }
  };

  return (
    <div className="Transaction">
      <TransTable availabe={Results !== "" ? true : false} />
      {
        loading && (<div className="SpinContain" >
            <span className="spinner-border spinner-grow-md"/>
        </div>)
      }
      {Results !== "" && !loading && (
        <table>
          <thead>
            <th>AMOUNT</th>
            <th>DESCRIPTION</th>
            <th>DATE</th>
            <th>TYPE</th>
          </thead>
          <tbody>
            {Output.map((item) => (
              <tr>
                <td>
                  <div className="Box">
                    &#8358;
                    {parseFloat(item.amount)?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </td>
                <td>
                  <div className="Box PayDescr">{item.description}</div>
                </td>
                <td>
                  <div className="Box">
                    <Moment format="MMM DD, YYYY">{item.date_time}</Moment>
                  </div>
                </td>
                <td>
                  <div className="Box">
                    <div className={`Btn ${renderColor(item.type)}`}>
                      {item.type}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {Results === "" && !loading && <EmptyWallet />}
      {Results !== "" && (
        <ShipmentNavigateButton
          CurrentPage={CurrentPage}
          setCurrentPage={setCurrentPage}
          output={Output}
          Length={Length}
        />
      )}
    </div>
  );
};

export default Transaction;
