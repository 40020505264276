import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
// import { InternationalActions } from "../../../store/InterState_Slice";
import { InternationalActions } from "../../../store/International_Slice";
import { GetAllCities, GetAllStatesUrl } from "../../../Library/URLs";
import axios from "axios";
import { generateTransactionID } from "../../../Library/Utilities";
import { toast } from "react-toastify";
import states from "../../../Data/states.json";
import rivers from "../../../Data/rivers.json";
import countries from "../../../Assets/Files/countries.json";

const SenderForm = ({ setTab }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isClear, setIsClear] = useState(false);

  const user_id = localStorage.getItem("user_id");
  const user_ses_id = localStorage.getItem("session_id");
  const company_id = localStorage.getItem("company_id");

  // Related state management for states dropdown
  const [PickState, setPickState] = useState("");
  const [ApiState, setApiState] = useState([]);
  const [SelectedState, setSelectedState] = useState("");
  const [showStateSuggestions, setShowStateSuggestions] = useState(false);
  const [StateError, setStateError] = useState(false);

  // Related state management for cities dropdown
  const [cities, setCities] = useState([]);
  const [SelectedCity, setSelectedCity] = useState("");
  const [showCitySuggestions, setShowCitySuggestions] = useState(false);
  const [CityError, setCityError] = useState(false);
  const [PickCity, setPickCity] = useState("");

  const dispatch = useDispatch();

  const [country, setCountry] = useState("");
  const [countryError, setcountryError] = useState(false);
  const [showCountriesSuggestions, setShowCountriesSuggestions] =
    useState(false);
  const [countriesSuggestions, setCountriesSuggestions] = useState([]);

  const { Sender, SenderCountry } = useSelector((state) => state.International);

  //   const { PriceDetails } = useSelector((state) => state.Interstate);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    const FetchState = async () => {
      const raw = {
        user_id,
        user_ses_id,
        company_id,
        transaction_id: generateTransactionID(15),
        country: country,
      };
      // console.log("post data",raw)
      await axios
        .post(GetAllStatesUrl, raw)
        .then((res) => {
          if (res.data.success === true) {
            setApiState(res.data.data);
          }
          if (res.data.success === false) {
            // toast.error(res.data.message);
          }
        })
        .catch((e) => {
          // toast.error("fetching of city error" + e.message);
        });
    };
    if (country !== "") {
      setApiState([]);
      FetchState();
    }

    // eslint-disable-next-line
  }, [country]);

  useEffect(() => {
    if (!isClear) {
      return;
    } else {
      reset();
    }
  }, [isClear, reset]);

  useEffect(() => {
    if (Sender !== "") {
      setCountry(SenderCountry);
      reset(Sender);
    }
  }, [Sender, SenderCountry, reset]);

  const onSubmit = (data) => {
    if (PickState === "") {
      setStateError(true);
      return;
    }

    if (PickCity === "") {
      setCityError(true);
      return;
    }

    if (country === "") {
      setcountryError(true);
      return;
    }
    if (data) {
      dispatch(
        InternationalActions.GetSender({
          ...data,
          state: PickState,
          city: PickCity,
          country: country,
        })
      );
      setTab(1);
      setIsClear(true);
    } else setIsClear(false);
  };

  const selectState = (location) => {
    setPickState(location);
    setStateError(false);
    setShowStateSuggestions(false);
  };

  const selectCity = (location) => {
    setPickCity(location);
    setCityError(false);
    setShowCitySuggestions(false);
  };

  const searchState = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setPickState(query);

    if (query === "") {
      setShowStateSuggestions(false);
      return;
    }
    const filteredStates = [];

    let searchStates = country === "Nigeria" ? states : ApiState;

    searchStates.forEach((state) => {
      if (state["name"].toLowerCase().includes(query.toLowerCase())) {
        filteredStates.push(state["name"]);
      }
    });

    setSelectedState(filteredStates);
    if (filteredStates.length > 0) {
      setShowStateSuggestions(true);
    } else {
      setShowStateSuggestions(false);
    }
  };

  const searchCities = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setPickCity(query);

    if (query === "") {
      setShowCitySuggestions(false);
      return;
    }

    const filteredCities = [];

    let searchCities = PickState === "Rivers State" ? rivers : cities;

    searchCities.forEach((city) => {
      if (city.toLowerCase().includes(query.toLowerCase())) {
        filteredCities.push(city);
      }
    });

    setSelectedCity(filteredCities);
    if (filteredCities.length > 0) {
      setShowCitySuggestions(true);
    } else {
      setShowCitySuggestions(false);
    }
  };

  const FetchCity = async () => {
    const raw = {
      user_id,
      user_ses_id,
      company_id,
      transaction_id: generateTransactionID(15),
      country: country,
      state:
        country === "Nigeria"
          ? states?.find((state) => state.name === PickState)?.name
          : ApiState?.find((state) => state.name === PickState)?.name,
    };
    // console.log("post data",raw)
    await axios
      .post(GetAllCities, raw)
      .then((res) => {
        if (res.data.success === true) {
          setCities(res.data.data);
        }
        if (res.data.success === false) {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
        // toast.error("fetching of city error" + e.message);
      });
  };

  useEffect(() => {
    if (PickState !== "") {
      FetchCity();
    }
  }, [PickState]);

  const selectCountry = (location) => {
    setCountry(location);
    setShowCountriesSuggestions(false);
  };

  const searchCountry = (query) => {
    setCountry(query);

    if (query === "") {
      setShowCountriesSuggestions(false);
      return;
    }

    const filteredCountries = [];
    countries.forEach((country) => {
      if (country["name"].toLowerCase().startsWith(query.toLowerCase())) {
        filteredCountries.push(country["name"]);
      }
    });

    setCountriesSuggestions(filteredCountries);
    if (filteredCountries.length > 0) {
      setShowCountriesSuggestions(true);
    } else {
      setShowCountriesSuggestions(false);
    }
  };

  return (
    <form data-aos="fade-zoom-in" onSubmit={handleSubmit(onSubmit)}>
      <div className="Top">
        <div className="Box">
          <label htmlFor="first">FIRST NAME</label>
          <input
            id="first"
            placeholder="First name"
            className={`${errors.first_name ? "error" : "noError"}`}
            {...register("first_name", {
              required: true,
              onChange: () =>
                dispatch(InternationalActions.GetPriceDetails("")),
            })}
          />
          {errors.first_name && (
            <span className={`${errors.first_name ? "errorText" : ""}`}>
              first name field is required
            </span>
          )}
        </div>
        <div className="Box">
          <label htmlFor="last">LAST NAME</label>
          <input
            id="last"
            placeholder="last name"
            className={`${errors.last_name ? "error" : "noError"}`}
            {...register("last_name", { required: true })}
          />
          {errors.last_name && (
            <span className={`${errors.last_name ? "errorText" : ""}`}>
              last name field is required
            </span>
          )}
        </div>
      </div>
      <div className="Box">
        <label htmlFor="PHONE NUMBER">EMAIL</label>
        <input
          id="phone-number"
          type="email"
          placeholder="Email"
          className={`${errors.sender_email ? "error" : "noError"}`}
          {...register("sender_email", { required: true })}
        />
        {errors.sender_email && (
          <span className={`${errors.sender_email ? "errorText" : ""}`}>
            Email field is required
          </span>
        )}
      </div>
      <div className="Box">
        <label htmlFor="PHONE NUMBER">PHONE NUMBER</label>
        <input
          id="phone-number"
          type="tel"
          placeholder="Phone Number"
          className={`${errors.mobile ? "error" : "noError"}`}
          {...register("mobile", { required: true })}
        />
        {errors.mobile && (
          <span className={`${errors.mobile ? "errorText" : ""}`}>
            Phone number field is required
          </span>
        )}
      </div>
      {/* <div className="Box">
        <label htmlFor="weight">Weight</label>
        <input
          id="weight"
          type="tel"
          placeholder="Weight"
          className={`${errors.weight ? 'error' : 'noError'}`}
          {...register('weight', { required: true })}
        />
        {errors.weight && (
          <span className={`${errors.weight ? 'errorText' : ''}`}>
            weight field is required
          </span>
        )}
      </div> */}
      <div className="Box">
        <label htmlFor="street">ADDRESS 1</label>
        <input
          id="street"
          placeholder="Address"
          className={`${errors.address_one ? "error" : "noError"}`}
          {...register("address_one", { required: true })}
          maxLength={35}
        />
        {errors.address_one && (
          <span className={`${errors.address_one ? "errorText" : ""}`}>
            Address field is required
          </span>
        )}
      </div>
      <div className="Box">
        <label htmlFor="house">ADDRESS 2 (Optional)</label>
        <input
          id="house"
          placeholder="Address 2 (Optional)"
          {...register("address_two")}
          maxLength={35}
        />
      </div>
      <div className="Top">
        <div className="Box">
          <label htmlFor="country">COUNTRY</label>
          <input
            id="country"
            placeholder="Country"
            value={country}
            className={`${countryError ? "error" : "noError"}`}
            onChange={(e) => searchCountry(e.target.value)}
          />
          {countryError && (
            <span className={`${countryError ? "errorText" : ""}`}>
              Country is required
            </span>
          )}
          <div
            style={{ width: "86%" }}
            className={`suggestions-field ${
              showCountriesSuggestions ? "suggestions-field-visible" : null
            }`}
          >
            {countriesSuggestions.map((suggestions) => {
              return (
                <div
                  onClick={(e) => selectCountry(suggestions, "Pickup")}
                  key={suggestions}
                  className="single-suggestion"
                >
                  <span style={{ fontSize: 14, color: "#74788d" }}>
                    {suggestions}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="Box">
          <label htmlFor="CITY">STATE</label>
          <input
            id="STATE"
            type="text"
            placeholder="State"
            value={PickState}
            onChange={(e) => searchState(e.target.value)}
            className={`${StateError === true ? "error" : "noError"}`}
          />
          {StateError === true && <span>State field is required</span>}
          <div
            className={`suggestions-field ${
              showStateSuggestions === true ? "suggestions-field-visible" : null
            }`}
          >
            {showStateSuggestions === true &&
              SelectedState.map((suggestions) => (
                <div
                  onClick={(e) => selectState(suggestions)}
                  key={suggestions}
                  className="single-suggestion"
                >
                  <span style={{ fontSize: 14, color: "#74788d" }}>
                    {suggestions}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="Top">
        <div className="Box">
          <label htmlFor="CITY">CITY</label>
          <input
            id="CITY"
            type="text"
            value={PickCity}
            placeholder="City"
            onChange={(e) => searchCities(e.target.value)}
            className={`${CityError === true ? "error" : "noError"}`}
          />
          {CityError === true && <span>City field is required</span>}
          <div
            className={`suggestions-field ${
              showCitySuggestions === true ? "suggestions-field-visible" : null
            }`}
          >
            {showCitySuggestions === true &&
              SelectedCity.map((suggestions) => (
                <div
                  onClick={(e) => selectCity(suggestions)}
                  key={suggestions}
                  className="single-suggestion"
                >
                  <span style={{ fontSize: 14, color: "#74788d" }}>
                    {suggestions}
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className="Box">
          <label htmlFor="POSTCODE">POSTCODE</label>
          <input
            id="POSTCODE"
            type="text"
            placeholder="Postcode"
            className={`${errors.post_code ? "error" : "noError"}`}
            {...register("post_code", { required: true })}
          />
          {errors.post_code && (
            <span className={`${errors.post_code ? "errorText" : ""}`}>
              Postcode field is required
            </span>
          )}
        </div>
      </div>
      <input type="submit" value="CONTINUE" />
      <button
        className="PrevBtn"
        style={{ backgroundColor: "#225AA4" }}
        onClick={(e) => {
          e.preventDefault();
          setTab(0);
        }}
        disabled
        type="button"
      >PREVIOUS</button>
    </form>
  );
};

export default SenderForm;
