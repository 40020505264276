import "../../Assets/Style/RegisterPage.scss"
import RegisterForm from "./components/FormPage";
import RegisterHeader from "./components/RegisterHeader";

const RegisterPage = () => {
    return (
        <div className="Register_Container_Fill">
            <div className="Left"></div>
            <div className="Right">
            <RegisterHeader/>
            <RegisterForm/>
            </div>
            <div className="Mobile_Phone"></div>
        </div>
    );
}
 
export default RegisterPage;