import green_check from "../../../Assets/Img/active_check.png"
import check from "../../../Assets/Img/inactive_check.png"


const Data=[
    {
     title:"Enter Sender Details",
     subtext:"Please fill in the requirement",
     active_img:green_check,
     inactive_img:check,
    },
    {
    title:"Enter Receiver Detail",
    subtext:"tap or click to add item",
    active_img:green_check,
    inactive_img:check,
    },
    {
    title:"Enter Package Details",
    subtext:"tap or click to add item",
    active_img:green_check,
    inactive_img:check,
    },
    {
    title:"Choose Payment Method",
    subtext:"tap or click to add item",
    active_img:green_check,
    inactive_img:check,
    },
    {
    title:"Confirm Details",
    subtext:"tap or click to add item",
    active_img:green_check,
    inactive_img:check,
    },
]

export default Data