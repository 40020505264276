import {useState} from "react";
import {Link} from "react-router-dom";
import "../../Assets/Style/LocalShipment.scss"
import {Sender, Receiver, Package, Payment, Confirm} from "./components/index";
import NavigatingTabs from "./components/Tabs";
import ItemDetailsForm from "./components/ItemDetailsForm";
import { useSelector } from "react-redux";

const TrackShipment = () => {
    const [Tab, setTab] = useState(0);
    const {PriceDetails}=useSelector((state)=>state.Local);
    const [isCalculated, setIsCalculated] = useState(true);
    const [shipmentFee, setShipmentFee] = useState("");

    return (
        <div className="Local_Shipment_Wrapper">
            <div className="header">
                <h3>
                    {Tab === 0 && "Track Shipment"}
                    {Tab === 1 && "Enter Items Details"}
                    {Tab === 2 && "Choose Payment Method"}
                    {Tab === 3 && "Confirm Booking Details"}
                </h3>

                {PriceDetails === "" ? null : <h3>Cost: NGN{PriceDetails.amount}</h3>}
            </div>

            <div className="Wrap">
            <div className="Right">
                    {Tab === 0 && <Sender setTab={setTab} setShipmentFee={setShipmentFee}/>}
                    {Tab === 1 && <ItemDetailsForm setTab={setTab} shipmentFee={shipmentFee} />}
                    {Tab === 2 && <Payment setTab={setTab}/>}
                    {Tab === 3 && <Confirm setTab={setTab}/>}
                </div>
                <div className="Left">
                    <NavigatingTabs Tab={Tab} setTab={setTab} shipmentFee={shipmentFee}/>
                    <div className="Terms">
                        Track shipment
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrackShipment;