import axios from 'axios'
import { toast } from 'react-toastify';
import { InternationalActions } from './International_Slice'
import {
  calculateInterStateCostURL,
  bookInterstateShipment,
} from '../Library/URLs'
import { generateTransactionID } from '../Library/Utilities'
const user_id = localStorage.getItem('user_id')
const user_ses_id = localStorage.getItem('session_id')
const company_id = localStorage.getItem('company_id')
const transaction_id = generateTransactionID(15)

export const CostData = (Sender,SenderRegion,Receiver,ReceiverRegion) => {
  return async (dispatch) => {
    dispatch(InternationalActions.Loading(true))
    const CalCost = async () => {
      let data
      const raw = {
        user_id: user_id,
        user_type: 'Regular',
        user_ses_id: user_ses_id,
        company_id: company_id,
        transaction_id: transaction_id,
        weight: Sender.weight,
        processor: 'DHL',
        sender_address: Sender.address,
        sender_country: SenderRegion,
        sender_city: Sender.city,
        sender_state: Sender.state,
        sender_zip_code: Sender.postcode,
        sender_phone: Sender.phone_number,
        receiver_address: Receiver.address,
        receiver_country: ReceiverRegion,
        receiver_city: Receiver.city,
        receiver_state: Receiver.state,
        receiver_zip_code: Receiver.postcode,
        receiver_phone_number: Receiver.phone_number,
        type: 'International',
        request_pickup: 'No',
      }
      console.log("raw data"+JSON.stringify(raw))
      const res = await axios.post(calculateInterStateCostURL, raw) // returns promise
      data = res.data
      return data
    }
    try {
      const interstateCost = await CalCost()
      console.log('res'+JSON.stringify(interstateCost))
      if (interstateCost.success === false) {
        dispatch(InternationalActions.TogglePrice(false))
        toast.error(interstateCost.message)
      }
      if (interstateCost.success === true) {
        dispatch(InternationalActions.TogglePrice(true))
        dispatch(InternationalActions.GetPriceDetails(interstateCost.data))
      }
      dispatch(InternationalActions.Loading(false))
    } catch (error) {
      console.log('calculate cost error' + error.message)
      toast.error('calculate cost error' + error.message)
      dispatch(InternationalActions.Loading(false))
    }
  }
}

export const BookInternationalShipment = (Data) => {
  return async (dispatch) => {
    dispatch(InternationalActions.Loading(true))
    console.log('book', Data)
    const BookShipment = async () => {
      let data

      const res = await axios.post(bookInterstateShipment, Data) // returns promise
      data = res.data
      return data
    }
    try {
      const BookInterState = await BookShipment()
      console.log('book', JSON.stringify(BookInterState))
      if (BookInterState.success === false) {
        dispatch(InternationalActions.ShipmentStatus(false))
        toast.error(BookInterState.message)
      }
      if (BookInterState.success === true) {
        toast.success('Shipment as been successfully book')
        dispatch(InternationalActions.ShipmentStatus(true))
      }
      dispatch(InternationalActions.Loading(false))
    } catch (error) {
      console.log('Shipment Book error' + error.message)
      toast.error('Shipment Book error' + error.message)
      dispatch(InternationalActions.Loading(false))
    }
  }
}


export const ClearRecord = () => {
  return async (dispatch) => {
    dispatch(InternationalActions.ShipmentStatus(false))
    dispatch(InternationalActions.GetOverall(''))
    dispatch(InternationalActions.GetOverall(''))
    dispatch(InternationalActions.GetPriceDetails(''))
    dispatch(InternationalActions.GetPayment(''))
    dispatch(InternationalActions.GetSender(''))
    dispatch(InternationalActions.GetSenderRegion(''))
    dispatch(InternationalActions.GetReceiver(''))
    dispatch(InternationalActions.GetReceiverRegion(''))
    dispatch(InternationalActions.ResetPackage([]))
    dispatch(InternationalActions.GetRequestPack(''))
    dispatch(InternationalActions.ResetTotalQuantity(0))
    dispatch(InternationalActions.ResetTotalValue(0))
    dispatch(InternationalActions.ResetTotalWeight(0))
  }
}
