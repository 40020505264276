import "../../../Assets/Style/Package.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { InternationalActions } from "../../../store/International_Slice";
// import { generateTransactionID } from "../../../Library/Utilities";
import axios from "axios";
import { toast } from "react-toastify";
import Slider from "react-slick";
import categories from "../../../Data/packageCategories.json";
import { FaPlusCircle, FaRegTrashAlt } from "react-icons/fa";
import { generateTransactionID } from "../../../Library/Utilities";
import { calculateInterStateCostURL } from "../../../Library/URLs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  outerWidth: "0px",
  borderRadius: "5px",
  p: 4,
};

const roundNumber = (number) => {
  let roundedNumber = Math.round(number * 10) / 10;
  return roundedNumber;
};

const PackageDetail = ({ setTab }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [calcLoading, setCalcLoading] = useState(false);

  const user_id = localStorage.getItem("user_id");
  const user_ses_id = localStorage.getItem("session_id");
  const company_id = localStorage.getItem("company_id");

  const [isEmpty, setisEmpty] = useState(false);
  const [Overall, setOverall] = useState("");
  const [OveralModal, setOveralModal] = useState(false);
  const handleOverallOpen = () => setOveralModal(true);
  const handleOverallClose = () => {
    setOverall(false);
  };
  const OveralSubmit = () => {
    if (Package[selectedPackage]?.description === "") {
      setisEmpty(true);
    }
    if (
      Package[selectedPackage]?.package_height === "" ||
      Package[selectedPackage]?.package_width === "" ||
      Package[selectedPackage]?.package_length === ""
    ) {
      setPackageIsEmpty(true);
    }
    if (
      Package[selectedPackage]?.description !== "" &&
      Package[selectedPackage]?.description.length > 3
    ) {
      // dispatch(InternationalActions.GetOverall(Overall));
      // dispatch(InternationalActions.GetPackagingInfo(packagingInfo));
      console.log(Overall);
      setisEmpty(false);
      setPackageIsEmpty(false);
      setOveralModal(false);
    }
  };

  const transaction_id = generateTransactionID(15);

  const [categoryQuery, setCategoryQuery] = useState("");
  const [catError, setCatError] = useState(false);
  const [showCategoriesSuggestions, setShowCategoriesSuggestions] =
    useState(false);
  const [categorySuggestions, setCategorySuggestions] = useState([]);

  const [showAddCatSuggestions, setShowAddCatSuggestions] = useState(false);
  const [addCatQuery, setAddCatQuery] = useState("");
  const [addCatSuggestions, setAddCatSuggestions] = useState([]);
  const [addCat, setAddCat] = useState("");

  const [showCarrier, setShowCarrier] = useState(false);
  const [carriers, setCarriers] = useState([]);

  const [EditPackage, setEditPackage] = useState(null);
  const [Editopen, setEditopen] = useState(false);
  const handleEditOpen = (item) => {
    setEditPackage(item);
    setEditopen(true);
  };
  const handleEditClose = () => setEditopen(false);

  const SubmitUpdate = () => {
    // dispatch(InternationalActions.EditPackage(EditPackage));
    editItem();
    setEditopen(false);
  };

  const selectEditCategory = (value) => {
    setEditPackage({
      ...EditPackage,
      name: categories.find((val) => val?.label === value)?.value,
      hs_code: categories.find((val) => val?.label === value)?.hs_code,
    });
    setCategoryQuery(value);
    setShowCategoriesSuggestions(false);
  };

  const searchCategory = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setCategoryQuery(query);

    if (query === "") {
      setShowCategoriesSuggestions(false);
      return;
    }

    const filteredCountries = [];
    categories.map((cat) => {
      if (cat["label"].toLowerCase().includes(query.toLowerCase())) {
        filteredCountries.push(cat["label"]);
      }
    });

    setCategorySuggestions(filteredCountries);
    if (filteredCountries.length > 0) {
      setShowCategoriesSuggestions(true);
    } else {
      setShowCategoriesSuggestions(false);
    }
  };

  const selectAddCategory = (value) => {
    setAddCat(value);
    setAddCatQuery(value);
    setShowAddCatSuggestions(false);
  };

  const searchAddCategory = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setAddCatQuery(query);

    if (query === "") {
      setShowAddCatSuggestions(false);
      return;
    }

    const filteredCountries = [];
    categories.map((cat) => {
      if (cat["label"].toLowerCase().includes(query.toLowerCase())) {
        filteredCountries.push(cat["label"]);
      }
    });

    setAddCatSuggestions(filteredCountries);
    if (filteredCountries.length > 0) {
      setShowAddCatSuggestions(true);
    } else {
      setShowAddCatSuggestions(false);
    }
  };

  const [selectedPackage, setSelectedPackage] = useState(0);
  // Link this ref to the tab slider
  const sliderTabRef = useRef();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isClear, setIsClear] = useState(false);

  const dispatch = useDispatch();
  const { Package, Sender, Receiver, PriceDetails } = useSelector(
    (state) => state.International
  );

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    if (!isClear) {
      return;
    } else {
      reset();
    }
  }, [isClear, reset]);

  // const onSubmit = (data) => {
  //   if (data) {
  //     console.log(data);
  //     dispatch(
  //       InternationalActions.GetPackage({
  //         id: Package.length + 1,
  //         ...data,
  //       })
  //     );
  //     dispatch(InternationalActions.GetQtyVal());
  //     dispatch(InternationalActions.GetRequestPack());
  //     setOpen(false);
  //     setIsClear(true);
  //     console.log(isClear);
  //   } else setIsClear(false);
  // };

  const SubmitForm = () => {
    if (Package.length < 1) {
      return;
    } else {
      calculateShipment();
    }
  };

  const calculateShipment = async () => {
    setIsLoading(true);

    let allPackageItems = [];

    // Outer loop to iterate through the varObj array
    for (let i = 0; i < Package.length; i++) {
      // Inner loop to iterate through the sports array for each object
      for (let j = 0; j < Package[i].package_items.length; j++) {
        // Push each sport object into the allSports array
        allPackageItems.push(Package[i].package_items[j]);
      }
    }

    const raw = {
      user_id,
      user_type: "Regular",
      user_ses_id,
      company_id,
      transaction_id,
      sender_zip_code: Sender?.post_code,
      receiver_zip_code: Receiver?.post_code,
      sender_address: Sender?.address_one,
      receiver_address: Receiver?.address_one,
      receiver_country: Receiver?.country,
      receiver_city: Receiver?.city,
      receiver_state: Receiver?.state,
      pickup_type: "None",
      insurance: "No",
      packaging: {
        length: "10",
        width: "10",
        height: "10",
      },
      packages: allPackageItems,
      type: "International",
      request_pickup: "No",
      packaging_weight: 0,
      sender_country: Sender?.country,
      sender_city: Sender?.city,
      sender_state: Sender?.state,
      sender_phone: Sender?.phone_number,
      receiver_phone_number: Receiver?.phone_number,
    };
    setCalcLoading(true);
    await axios.post(calculateInterStateCostURL, raw).then((res) => {
      console.log("inter res", res.data);
      setIsLoading(false);
      setCalcLoading(false);
      if (res.data?.success) {
        // InternationalActions.GetPriceDetails("")
        // dispatch(InternationalActions.GetPriceDetails(res.data?.data?.carriers));
        dispatch(InternationalActions.GetPriceDetails(""));
        setCarriers(res.data?.data?.carriers);
        setShowCarrier(true);
        // dispatch(InternationalActions.GetPackageStatus(true));
      } else if (res.data?.success === false) {
        toast.error(res.data?.message);
      }
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [packageIsEmpty, setPackageIsEmpty] = useState(false);

  const addPackage = () => {
    const packagesCopy = [...Package];

    packagesCopy.push({
      package_items: [],
      package_weight: "0",
      package_length: "",
      package_width: "",
      package_height: "",
      package_value: "0",
      number_of_items: "0",
    });
    goToSlide(packagesCopy?.length - 1);

    dispatch(InternationalActions.UpdatePackage(packagesCopy));
  };

  const removePackage = (packageId) => {
    const packagesCopy = [...Package];

    packagesCopy?.splice(packageId, 1);
    goToSlide(selectedPackage - 1);
    dispatch(InternationalActions.UpdatePackage(packagesCopy));
  };

  // Funtion to update the current content
  const goToSlide = (index) => {
    if (sliderTabRef.current) {
      setSelectedPackage(index);
      sliderTabRef.current.slickGoTo(index);
    }
  };

  // Slider settings for package contents
  // After change gets the index of the current slide
  const packageSettings = {
    afterChange: (currentSlide) => {
      console.log("iiwiw", currentSlide);
      setSelectedPackage(parseInt(currentSlide));
    },
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: false,
    // centerPadding: "20px",
    arrows: false,
  };

  // Slider settings for contents tab
  const tabSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: false,
    centerMode: false,
  };

  const removeItem = (id, packageId) => {
    let packages = [...Package];
    let packageItems = [...Package[packageId]?.package_items];

    packageItems.splice(id, 1);

    let itemWeight =
      parseFloat(Package[packageId]?.package_weight) -
      parseFloat(Package[packageId][id]?.weight);

    let new_item_value =
      parseFloat(Package[packageId]?.package_value) -
      (parseFloat(Package[packageId][id]?.value) ?? 0) *
        parseInt(Package[packageId][id]?.quantity ?? 0);
    let new_quantity =
      parseFloat(Package[packageId]?.number_of_items) -
      parseFloat(Package[packageId][id]?.quantity);

    packages[packageId] = {
      ...packages[packageId],
      package_items: packageItems,
      package_weight: itemWeight,
      package_value: new_item_value,
      number_of_items: new_quantity,
    };

    dispatch(InternationalActions.UpdatePackage(packages));
    setEditopen(false);
  };

  const addPackageItem = (data) => {
    // console.log("osp", data);
    // let packageCount = (Object.keys(userForm.packages).length + 1).toString();
    if (addCat !== "") {
      const copyPackages = [...Package];
      if (Package[0]?.package_items?.length > 0) {
        let initialPackageCurrency = Package[0]?.package_items[0]?.currency;
        if (data?.currency !== initialPackageCurrency) {
          toast.error(
            "We noticed differences in your package currency. To ensure your shipment details is accurate, please ensure you maintain a single currency"
          );
        }
      }
      setCatError(false);
      console.log("ppalf", selectedPackage);
      console.log("pawwla", Package);
      const copyPackageItems = [...Package[selectedPackage]?.package_items];
      copyPackageItems.push({
        ...data,
        name: categories.find((cat) => cat.label === addCat)?.value,
        hs_code: categories.find((cat) => cat.label === addCat)?.hs_code,
      });
      console.log("pdf", copyPackageItems);
      copyPackages[selectedPackage] = {
        ...copyPackages[selectedPackage],
        package_items: copyPackageItems,
        package_weight: roundNumber(
          parseFloat(copyPackages[selectedPackage]?.package_weight) +
            parseFloat(data.weight)
        ),
        package_value:
          parseFloat(copyPackages[selectedPackage]?.package_value) +
          parseFloat(data.value) * parseInt(data.quantity),
        number_of_items:
          parseFloat(copyPackages[selectedPackage]?.number_of_items) +
          parseInt(data.quantity),
      };

      dispatch(InternationalActions.UpdatePackage(copyPackages));
      setOpen(false);
      // setIsClear(true);
      reset();
    } else {
      setCatError(true);
    }
  };

  const editItem = () => {
    // let packageCount = (Object.keys(userForm.packages).length + 1).toString();
    const copyPackages = [...Package];
    const copyPackageItems = [...Package[selectedPackage]?.package_items];

    if (Package[0]?.package_items?.length > 0) {
      let initialPackageCurrency = Package[0]?.package_items[0]?.currency;
      if (EditPackage?.currency !== initialPackageCurrency) {
        toast.error(
          "We noticed differences in your package currency. To ensure your shipment details is accurate, please ensure you maintain a single currency"
        );
      }
    }

    copyPackageItems[EditPackage.id] = {
      ...EditPackage,
    };
    console.log("llaka", copyPackageItems);
    copyPackages[selectedPackage] = {
      ...copyPackages[selectedPackage],
      package_items: copyPackageItems,
      package_weight: roundNumber(
        parseFloat(copyPackages[selectedPackage]?.package_weight) -
          parseFloat(
            Package[selectedPackage]?.package_items[EditPackage.id]?.weight
          ) +
          parseFloat(EditPackage.weight)
      ),
      package_value:
        parseFloat(copyPackages[selectedPackage]?.package_value) -
        parseFloat(
          Package[selectedPackage]?.package_items[EditPackage.id]?.value
        ) *
          parseInt(
            Package[selectedPackage]?.package_items[EditPackage.id]?.quantity
          ) +
        parseFloat(EditPackage.value) * parseInt(EditPackage.quantity),
      number_of_items:
        parseInt(copyPackages[selectedPackage]?.number_of_items) -
        parseFloat(
          Package[selectedPackage]?.package_items[EditPackage.id]?.quantity
        ) +
        parseInt(EditPackage.quantity),
    };

    dispatch(InternationalActions.UpdatePackage(copyPackages));
  };

  const handleSummaryInput = (e) => {
    const packagesCopy = [...Package];

    packagesCopy[selectedPackage] = {
      ...packagesCopy[selectedPackage],
      [e.target.name]: e.target.value,
    };
    dispatch(InternationalActions.UpdatePackage(packagesCopy));
  };

  return (
    <>
      <div data-aos="fade-zoom-in" className="Package_Detail shipment-slick">
        <div className="Top">
          <div className="d-flex w-100">
            <div className="d-block w-100">
              <Slider ref={sliderTabRef} {...tabSettings}>
                {Package.map((packageItem, id) => (
                  <button
                    type="button"
                    className={`${selectedPackage !== id && "btnTab"}`}
                    onClick={() => goToSlide(id)}
                  >
                    Package {id + 1}
                  </button>
                ))}
              </Slider>
            </div>
            {/* <button
              onClick={addPackage}
              className="btnTab d-none d-md-flex gap-2 justify-content-center align-items-center"
              style={{ minWidth: "20%" }}
            >
              Add Package
              <FaPlusCircle style={{ color: "#E92D38" }} />
            </button> */}
          </div>
          <div className="d-flex gap-5 mb-4">
            <button
              onClick={addPackage}
              className="btnTab d-flex gap-2 justify-content-center align-items-center"
            >
              Add Package
              <FaPlusCircle style={{ color: "#E92D38" }} />
            </button>
            <button
              className="btnTab"
              disabled={Package?.length < 2}
              onClick={() => removePackage(selectedPackage)}
            >
              Remove Package
            </button>
          </div>
          {/* <button
            className=" d-none d-md-flex gap-2 justify-content-center align-items-center"
            disabled={Package?.length < 2}
            style={{
              border: "none",
              color: "#6D7D93",
              backgroundColor: "transparent",
            }}
            onClick={() => removePackage(selectedPackage)}
          >
            <FaRegTrashAlt className="text-danger" />
            Remove this Package
          </button> */}
          <Slider ref={sliderTabRef} {...packageSettings}>
            {Package?.map((PackageItems, index) =>
              PackageItems.package_items?.length > 0 ? (
                <div key={index}>
                  {PackageItems?.package_items.map((item, id) => (
                    <div className="Box" key={index}>
                      <div className="Left">
                        <div className="circle">{item.quantity}</div>
                        <div className="Detail">
                          <h4>{item.name}</h4>
                          <h5>{item.description}</h5>
                        </div>
                      </div>
                      <div
                        onClick={() => handleEditOpen({ ...item, id: id })}
                        className="Right"
                      >
                        <svg
                          width="17"
                          height="5"
                          viewBox="0 0 17 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.0488 2.94775C12.0488 2.41732 12.2595 1.90861 12.6346 1.53354C13.0097 1.15847 13.5184 0.947754 14.0488 0.947754C14.5793 0.947754 15.088 1.15847 15.463 1.53354C15.8381 1.90861 16.0488 2.41732 16.0488 2.94775C16.0488 3.47819 15.8381 3.98689 15.463 4.36197C15.088 4.73704 14.5793 4.94775 14.0488 4.94775C13.5184 4.94775 13.0097 4.73704 12.6346 4.36197C12.2595 3.98689 12.0488 3.47819 12.0488 2.94775ZM6.04883 2.94775C6.04883 2.41732 6.25954 1.90861 6.63461 1.53354C7.00969 1.15847 7.51839 0.947754 8.04883 0.947754C8.57926 0.947754 9.08797 1.15847 9.46304 1.53354C9.83811 1.90861 10.0488 2.41732 10.0488 2.94775C10.0488 3.47819 9.83811 3.98689 9.46304 4.36197C9.08797 4.73704 8.57926 4.94775 8.04883 4.94775C7.51839 4.94775 7.00969 4.73704 6.63461 4.36197C6.25954 3.98689 6.04883 3.47819 6.04883 2.94775ZM0.0488281 2.94775C0.0488281 2.41732 0.259542 1.90861 0.634614 1.53354C1.00969 1.15847 1.5184 0.947754 2.04883 0.947754C2.57926 0.947754 3.08797 1.15847 3.46304 1.53354C3.83811 1.90861 4.04883 2.41732 4.04883 2.94775C4.04883 3.47819 3.83811 3.98689 3.46304 4.36197C3.08797 4.73704 2.57926 4.94775 2.04883 4.94775C1.5184 4.94775 1.00969 4.73704 0.634614 4.36197C0.259542 3.98689 0.0488281 3.47819 0.0488281 2.94775Z"
                            fill="#151A42"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="Box">
                  <div className="Left">
                    <div className="Detail">
                      <h4>No package yet</h4>
                    </div>
                  </div>
                </div>
              )
            )}
          </Slider>
          <div onClick={handleOpen} className="chip">
            Add Item
          </div>
        </div>
        <h4>SUMMARY</h4>
        <ul>
          <li>
            TOTAL QUANTITY
            <span>{Package[selectedPackage]?.package_items?.length}</span>
          </li>
          <li>
            TOTAL VALUE{" "}
            <span>
              {Package[selectedPackage]?.package_items?.length === 0
                ? 0
                : Package[selectedPackage]?.package_items?.reduce(
                    (sum, acc) => sum + parseFloat(acc?.value),
                    0
                  )}
            </span>
          </li>
          <li>
            Overall Description
            <div onClick={handleOverallOpen} className="btn change">
              change
            </div>
          </li>
        </ul>
        <small>
          By continuing, I represent that the declaration above is a proper and
          accurate description of the contents of my package.
        </small>
        <button onClick={SubmitForm}>
          {calcLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="btn"
            >
              <span className="spinner-border spinner-grow-sm" />
            </div>
          ) : (
            "CONTINUE"
          )}
        </button>
        <button
          className="PrevBtn"
          style={{ backgroundColor: "#225AA4" }}
          onClick={(e) => {
            e.preventDefault();
            setTab(1);
          }}
          type="button"
        >
          PREVIOUS
        </button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="Modal_Wrapper">
              <div className="Box">
                <label htmlFor="des">CATEGORY</label>
                {/* <input
                  type="text"
                  placeholder="ex. Brown laptop bag made of cotton"
                  className={`${errors.name ? "error" : ""}`}
                  {...register("name", { required: true })}
                /> */}
                <input
                  type="text"
                  id="Name"
                  placeholder="Category"
                  autoComplete="false"
                  autoCorrect="false"
                  className={`${catError ? "error" : ""} Country`}
                  value={addCatQuery}
                  onChange={(e) => searchAddCategory(e.target.value)}
                />
                <div
                  style={{ width: "86%" }}
                  className={`suggestions-field ${
                    showAddCatSuggestions ? "suggestions-field-visible" : null
                  }`}
                >
                  {addCatSuggestions.map((suggestions) => {
                    return (
                      <div
                        onClick={() => selectAddCategory(suggestions)}
                        key={suggestions}
                        className="single-suggestion"
                      >
                        <span style={{ fontSize: 14, color: "#74788d" }}>
                          {suggestions}
                        </span>
                      </div>
                    );
                  })}
                </div>
                {catError && (
                  <span className={`${catError ? "errorText" : ""}`}>
                    Category field is required
                  </span>
                )}
              </div>
              {/* <p>Give the category of what you are shipping</p> */}
              <div className="Box">
                <label htmlFor="cat">DESCRIPTION</label>
                <textarea
                  placeholder="Enter the description of the item"
                  className={`${errors.description ? "error" : ""}`}
                  {...register("description", { required: true })}
                ></textarea>
                {errors.description && (
                  <span className={`${errors.description ? "errorText" : ""}`}>
                    Description field is required
                  </span>
                )}
              </div>
              {/* <p>
                Give a brief description that best describes your item. This is
                required for effective customs clearance when you ship
                internationally.
              </p> */}
              <div className="Top">
                <div className="Box">
                  <label htmlFor="cat">WEIGHT (KG)</label>
                  <input
                    type="tel"
                    placeholder="1"
                    className={`${errors.weight ? "error" : ""}`}
                    {...register("weight", { required: true })}
                  />
                  {errors.weight && (
                    <span className={`${errors.weight ? "errorText" : ""}`}>
                      Weight field is required
                    </span>
                  )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">QUANTITY</label>
                  <input
                    type="tel"
                    placeholder="Enter quantity"
                    className={`${errors.quantity ? "error" : ""}`}
                    {...register("quantity", { required: true })}
                  />
                  {errors.quantity && (
                    <span className={`${errors.quantity ? "errorText" : ""}`}>
                      Quantity field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="Top">
                <div className="Box">
                  <label htmlFor="cat">VALUE PER ITEM</label>
                  <input
                    type="tel"
                    placeholder="Enter value"
                    className={`${errors.value ? "error" : ""}`}
                    {...register("value", { required: true })}
                  />
                  {errors.value && (
                    <span className={`${errors.value ? "errorText" : ""}`}>
                      Value field is required
                    </span>
                  )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">CURRENCY</label>
                  <select
                    name="currency"
                    className={`${errors.currency ? "error" : ""}`}
                    {...register("currency", { required: true })}
                  >
                    <option value="">Choose Currency</option>
                    <option value="USD">USD</option>
                    <option value="NGN">NGN</option>
                  </select>
                  {errors.currency && (
                    <span className={`${errors.currency ? "errorText" : ""}`}>
                      Currency is required
                    </span>
                  )}
                </div>
              </div>
              <button onClick={handleSubmit(addPackageItem)}>ADD ITEM</button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={Editopen}
        onClose={handleEditClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={Editopen}>
          <Box sx={style}>
            {EditPackage !== null && (
              <div className="Modal_Wrapper">
                <div className="Box">
                  <label htmlFor="des">CATEGORY</label>
                  {/* <input
                    type="text"
                    value={EditPackage.name}
                    onChange={(e) =>
                      setEditPackage((pre) => ({
                        ...pre,
                        name: e.target.value,
                      }))
                    }
                  /> */}
                  <input
                    type="text"
                    id="Name"
                    placeholder="Category"
                    autoComplete="false"
                    autoCorrect="false"
                    value={categoryQuery}
                    // className={`Country ${
                    //   countryError === true ? "error" : "noError"
                    // }`}
                    className={`Country`}
                    onChange={(e) => searchCategory(e.target.value)}
                  />
                  {/* {countryError && (
              <span className={`${countryError ? "errorText" : ""}`}>
                Country is required
              </span>
            )} */}
                  <div
                    style={{ width: "86%" }}
                    className={`suggestions-field ${
                      showCategoriesSuggestions
                        ? "suggestions-field-visible"
                        : null
                    }`}
                  >
                    {categorySuggestions.map((suggestions) => {
                      return (
                        <div
                          onClick={() => selectEditCategory(suggestions)}
                          key={suggestions}
                          className="single-suggestion"
                        >
                          <span style={{ fontSize: 14, color: "#74788d" }}>
                            {suggestions}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* <p>Give the category of what you are shipping</p> */}
                <div className="Box">
                  <label htmlFor="cat">DESCRIPTION</label>
                  <textarea
                    value={EditPackage.description}
                    onChange={(e) =>
                      setEditPackage((pre) => ({
                        ...pre,
                        description: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
                {/* <p>
                  Give a brief description that best describes your item. This
                  is required for effective customs clearance when you ship
                  internationally.
                </p> */}
                <div className="Top">
                  <div className="Box">
                    <label htmlFor="cat">WEIGHT (KG)</label>
                    <input
                      type="tel"
                      value={EditPackage.weight}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          weight: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="Box">
                    <label htmlFor="cat">QUANTITY</label>
                    <input
                      type="tel"
                      value={EditPackage.quantity}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          quantity: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="Top">
                  <div className="Box">
                    <label htmlFor="cat">VALUE</label>
                    <input
                      type="tel"
                      value={EditPackage.value}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          value: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="Box">
                    <label htmlFor="cat">CURRENCY</label>
                    <select
                      name="currency"
                      value={EditPackage.currency}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          currency: e.target.value,
                        }))
                      }
                    >
                      <option value="">Choose Currency</option>
                      <option value="USD">uSD</option>
                      <option value="NGN">NGN</option>
                    </select>
                  </div>
                </div>
                {/* <p>
                  Provide the value and quantity of the item you are trying to
                  declare for shipping.
                </p> */}
                <div className="d-flex gap-4">
                  <button
                    className="btnTab"
                    onClick={() => removeItem(EditPackage?.id, selectedPackage)}
                  >
                    REMOVE ITEM
                  </button>
                  <button onClick={SubmitUpdate}>UPDATE ITEM</button>
                </div>
                {/* <button onClick={SubmitUpdate}>UPDATE ITEM</button> */}
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={OveralModal}
        onClose={() => setOveralModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={OveralModal}>
          <Box sx={style}>
            <div className="Modal_Wrapper">
              <div className="Triple_Box">
                <div className="Box">
                  <label htmlFor="cat">Length(CM)</label>
                  <input
                    value={Package[selectedPackage]?.package_length}
                    onChange={handleSummaryInput}
                    name="package_length"
                    placeholder="Enter Length"
                    className={`${
                      packageIsEmpty &&
                      Package[selectedPackage]?.package_length === ""
                        ? "error"
                        : ""
                    }`}
                  />
                  {packageIsEmpty &&
                    Package[selectedPackage]?.package_length === "" && (
                      <span className={`${isEmpty ? "errorText" : ""}`}>
                        Length is required
                      </span>
                    )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">Width(CM)</label>
                  <input
                    value={Package[selectedPackage]?.package_width}
                    onChange={handleSummaryInput}
                    name="package_width"
                    placeholder="Enter Width"
                    className={`${
                      packageIsEmpty &&
                      Package[selectedPackage]?.package_width === ""
                        ? "error"
                        : ""
                    }`}
                  />
                  {packageIsEmpty &&
                    Package[selectedPackage]?.package_width === "" && (
                      <span className={`${isEmpty ? "errorText" : ""}`}>
                        Width is required
                      </span>
                    )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">Height(CM)</label>
                  <input
                    value={Package[selectedPackage]?.package_height}
                    onChange={handleSummaryInput}
                    name="package_height"
                    placeholder="Enter Height"
                    className={`${
                      packageIsEmpty &&
                      Package[selectedPackage]?.package_height === ""
                        ? "error"
                        : ""
                    }`}
                  />
                  {packageIsEmpty &&
                    Package[selectedPackage]?.package_height === "" && (
                      <span className={`${isEmpty ? "errorText" : ""}`}>
                        Height is required
                      </span>
                    )}
                </div>
              </div>
              <div className="Box">
                <label htmlFor="cat">DESCRIPTION</label>
                <textarea
                  value={Package[selectedPackage]?.description}
                  onChange={handleSummaryInput}
                  name="description"
                  placeholder="Enter overall description of the item"
                  className={`${isEmpty ? "error" : ""}`}
                ></textarea>
                {isEmpty && (
                  <span className={`${isEmpty ? "errorText" : ""}`}>
                    Overall Description is required
                  </span>
                )}
              </div>
              <button onClick={OveralSubmit}>ADD OVERALL</button>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/*Carriers*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showCarrier}
        onClose={() => setShowCarrier(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showCarrier}>
          <Box sx={style}>
            <div className="Modal_Wrapper">
              <div className="Price_Page">
                {/* <h3>Prices</h3> */}

                {carriers?.map((carrier, id) => (
                  <div
                    onClick={
                      carrier?.selling_price === "Coming Soon"
                        ? () => {}
                        : () => {
                            // setPick(carrier?.carrier_id);
                            dispatch(
                              InternationalActions.GetPriceDetails(carrier)
                            );
                          }
                    }
                    className={`Price_Card ${
                      PriceDetails?.carrier_id === carrier?.carrier_id
                        ? "active"
                        : ""
                    }`}
                    key={id}
                  >
                    <div className="Wrap">
                      <div className="Logo">
                        <img src={carrier?.carrier_logo} alt="" />
                      </div>
                      <div className="Right">
                        <h2>
                          {carrier?.selling_price === "Coming Soon"
                            ? carrier?.selling_price
                            : `${
                                carrier?.currency === "USD"
                                  ? "$"
                                  : carrier?.currenccy === "NGN"
                                  ? "₦"
                                  : "₦"
                              }${carrier?.selling_price}`}
                        </h2>
                      </div>
                    </div>
                    <div className="Wrap">
                      <div className="Left Name">{carrier?.carrier_name}</div>
                      <div className="Right">{carrier?.delivery_time}</div>
                    </div>
                  </div>
                ))}
                <button
                  onClick={() => setTab(3)}
                  disabled={PriceDetails === ""}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PackageDetail;
