import React from "react";

const Info = () => {
  return (
    <div className="InfoSection">
      <div>
        <h1 className="InfoHero">We are here to help</h1>
        <p className="InfoSub">
          Walk in to any our customer service centers to have your questions
          answered and your packages shipped safely without stress
        </p>
        <div className="AddressContain">
          <div className="AddressCard">
            <p className="AddressState">LAGOS OFFICE</p>
            <p>3 Gani Williams Close Off Osolo Way Lagos Nigeria</p>
            <div className="PhoneContain" >
              <p className="m-0" >+234 906 268 2309</p>
              <p className="m-0" >+234 906 268 2308</p>
              <p>+234 706 448 9398</p>
            </div>
          </div>
          <div className="AddressCard">
            <p className="AddressState">ABUJA OFFICE</p>
            <p>Suite 4, Jam Nab Plaza, Sapele Street Abuja Nigeria</p>
            <p>+234 906 244 7821</p>
          </div>
          <div className="AddressCard">
            <p className="AddressState">PORT-HARCOURT OFFICE</p>
            <p>Yves Plaza, No 68, Tombia Extension, New Gra Phase 2 </p>
            <p>+234 814 672 8650</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
