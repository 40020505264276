import React, { useState } from "react";
import "../../Assets/Style/GetRates.scss";
import { SelectInput, TextArea, Textbox } from "../../components/FormInputs";
import Button from "../../components/Button";
import Header from "../../components/Header";
import HomeFooter from "../../components/Footer";
import Aramex from "../../Assets/Img/aramex3.png";
import DHL from "../../Assets/Img/dhl3.png";
import UPS from "../../Assets/Img/ups3.png";
import Fedex from "../../Assets/Img/fedex3.png";
import countries from "../../Data/countries.json";
import cities from "../../Data/cities.json";
import axios from "axios";
import { generateTransactionID } from "../../Library/Utilities";
import { toast } from "react-toastify"

const GetRates = () => {
  const [formData, setFormData] = useState({
    pickup_city: "",
    pickup_country: "",
    dropoff_city: "",
    dropoff_country: "",
    weight: "",
  });

  const [rateType, setRateType] = useState("International");
  const [isLoading, setIsLoading] = useState(false);
  const [shippingPrices, setShippingPrices] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleToggle = () => {
    if(rateType==="International") {
      setRateType("Interstate");
    } else {
      setRateType("International");
    }
  };

  const calculateShippingRate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await axios
      .post(
        "https://parcelflow-user-backend-7948506154d4.herokuapp.com/engine/v1/actions/get-rates",
        {
          user_id: "5126",
        transaction_id: generateTransactionID(15),
        company_id: "44",
        user_ses_id: "9531c2794d712f9980f05026a3285d69",
          from:
            rateType === "International"
              ? formData.pickup_country
              : formData.pickup_city,
          to:
            rateType === "International"
              ? formData.dropoff_country
              : formData.dropoff_city,
          weight: formData.weight,
          shipment_type: rateType,
        }
      )
      .then((res) => {
        setIsLoading(false);
        if(res?.data?.success) {
          setShippingPrices(res?.data?.data?.carriers);
          setShowModal(true);
          toast.success("Shipment calculated");

          setTimeout(() => {
            scrollToPrices();
          }, 1000);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("An error occured")
      });
  };

  // const scrollToGetRates = () => {
  //   let targetId = document.getElementById("getRates");
  //   targetId.scrollIntoView({ behavior: "smooth" });
  // };

  const scrollToPrices = () => {
    let targetId = document.getElementById("RatesResults");
    targetId.scrollIntoView({ behavior: "smooth" });
  };

  const countriesOption = countries?.map((item) => {
    return { label: item?.country, value: item?.country };
  });

  const citiesOption = cities?.map((item) => {
    return { label: item?.city_name, value: item?.city_name };
  });

  const handleDropdownInput = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <Header />
      <div className="GetRatesWrapper">
        <div className="RatesSection">
          <div className="InfoSection" >
            <h1 className="InfoHero">Get Quotes</h1>
            <p className="InfoSub">
            Get an estimate of shipping cost to find out how much it cost to delivery your packages
            </p>
          </div>
          <div className="RatesFormContain">
            <div className="GetRatesForm">
              <div>
                <div className="FormContain">
                  <div>
                    <div className="toggleContain" onClick={handleToggle} >
                      <div className={`toggleBtn ${rateType==="International" && "left"}`}>
                        <div className="toggleBtnItem"></div>
                      </div>
                      <div className={`toggleDiv ${rateType==="Interstate" && "active"}`}>Interstate</div>
                      <div className={`toggleDiv ${rateType==="International" && "active"}`}>International</div>
                    </div>
                  </div>
                  {/* <div className="FormGrid">
                    <Textbox
                      name="pickup_country"
                      label="Pickup Country"
                      value={formData.pickup_country}
                      onChange={handleChange}
                    />
                    <Textbox
                      name="pickup_city"
                      label="Pickup City"
                      value={formData.pickup_city}
                      onChange={handleChange}
                    />
                  </div> */}
                  <div className="TextAreaContain">
                    {
                      rateType === "International" ? (
                        <SelectInput 
                          options={countriesOption}
                          label="Pickup Country"
                          name={"pickup_country"}
                          value={formData.pickup_country}
                          handleChange={handleDropdownInput}
                        />
                      ) : (
                        <SelectInput 
                          options={citiesOption}
                          label="Pickup City"
                          name={"pickup_city"}
                          value={formData.pickup_city}
                          handleChange={handleDropdownInput}
                        />
                      )
                    }
                  </div>
                  <div className="TextAreaContain">
                    {
                      rateType === "International" ? (
                        <SelectInput 
                          options={countriesOption}
                          label="Dropoff Country"
                          name={"dropoff_country"}
                          value={formData.dropoff_country}
                          handleChange={handleDropdownInput}
                        />
                      ) : (
                        <SelectInput 
                          options={citiesOption}
                          label="Dropoff City"
                          name={"dropoff_city"}
                          value={formData.dropoff_city}
                          handleChange={handleDropdownInput}
                        />
                      )
                    }
                  </div>
                  <div className="TextAreaContain">
                    <Textbox
                      name="weight"
                      label="Weight"
                      value={formData.weight}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="BtnWrap">
                    <Button
                      text={
                        isLoading ? (
                          <span className="spinner-border spinner-grow-sm" />
                        ) : (
                          "CALCULATE RATE"
                        )
                      }
                      onClick={calculateShippingRate}
                      color={"#E92D38"}
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CarrierSection" id="RatesResults">
          <div className="CarrierWrap" >
            {
              shippingPrices?.map((carrier) => (
                <div key={carrier?.carrier_id} className="CarriersContain">
                  <img src={carrier?.carrier_logo} alt="dhl" />
                  <div>
                    <p className="CarrierName">{carrier?.carrier_name} </p>
                    <p className="MobWorkDays">{carrier?.delivery_time} </p>
                    {/* <p className="CarrierInfo">Best of time sensitive</p> */}
                  </div>
                  <div className="" >
                    <p className="WorkDays DeskWorkDays">{carrier?.delivery_time} </p>
                  </div>
                  <div>
                    <p className="CarrierAmount">&#8358; {carrier?.selling_price} </p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default GetRates;
