import { useState } from "react";
import { Link } from "react-router-dom";
import "../../Assets/Style/LocalShipment.scss"
import { Sender, Receiver, Package, Payment, Confirm } from "./components/index";
import NavigatingTabs from "./components/Tabs";
import ItemDetailsForm from "./components/ItemDetailsForm";
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";

const CreateLocalShipment = () => {
    const [Tab, setTab] = useState(0);
    const { PriceDetails } = useSelector((state) => state.Local);
    const [isCalculated, setIsCalculated] = useState(true);
    const [shipmentFee, setShipmentFee] = useState("");

    return (
        <div className="Local_Shipment_Wrapper">
            <span className="d-md-none mb-3" >
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="py-2" >Create Shipment Progress</Accordion.Header>
                        <Accordion.Body>
                            <NavigatingTabs setTab={setTab} Tab={Tab} shipmentFee={shipmentFee} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </span>
            <div className="header">
                <h3>
                    {Tab === 0 && "Enter Destinations"}
                    {Tab === 1 && "Enter Items Details"}
                    {Tab === 2 && "Choose Payment Method"}
                    {Tab === 3 && "Confirm Booking Details"}
                </h3>

                {PriceDetails === "" ? null : <h3>Cost: NGN{PriceDetails.amount}</h3>}
            </div>

            <div className="Wrap">
                <div className="Left">
                    <span className="d-none d-md-block" >
                        <NavigatingTabs setTab={setTab} Tab={Tab} shipmentFee={shipmentFee} />
                    </span>
                    <div className="Terms d-none d-md-block">
                        By completing this transaction, you represent that you have read,
                        understand and accept the Zee Express{" "}
                        <Link to="/local-shipment">terms of service</Link> and{" "}
                        <Link to="/local-shipment">privacy policy</Link>.
                    </div>
                </div>
                <div className="Right">
                    {Tab === 0 && <Sender setTab={setTab} setShipmentFee={setShipmentFee} />}
                    {Tab === 1 && <ItemDetailsForm setTab={setTab} shipmentFee={shipmentFee} />}
                    {Tab === 2 && <Payment setTab={setTab} />}
                    {Tab === 3 && <Confirm setTab={setTab} />}
                    <div className="Terms d-md-none">
                        By completing this transaction, you represent that you have read,
                        understand and accept the Zee Express{" "}
                        <Link to="/local-shipment">terms of service</Link> and{" "}
                        <Link to="/local-shipment">privacy policy</Link>.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateLocalShipment;