import AppRoutes from './Routes';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Fragment } from 'react';

function App() {
  return (
    <Fragment>
    <AppRoutes/>
    <ToastContainer />
    </Fragment>
  );
}

export default App;
