import React from 'react'
import "../../Assets/Style/Contact.scss"
import Header from '../../components/Header'
import HomeFooter from '../../components/Footer'
import Info from './components/Info'
import ContactForm from './components/ContactForm'

const Contact = () => {
  return (
    <div className='ContactWrapper' >
      <Header />
      <Info />
      <ContactForm />
      <HomeFooter />
    </div>
  )
}

export default Contact