const arrow=<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.0029 1.0799C8.3434 0.739397 8.89548 0.739397 9.23598 1.0799L12.7237 4.56759C13.0642 4.9081 13.0642 5.46017 12.7237 5.80068L9.23598 9.28837C8.89548 9.62888 8.3434 9.62888 8.0029 9.28837C7.66239 8.94786 7.66239 8.39579 8.0029 8.05528L10.0021 6.05606H0.953466C0.471916 6.05606 0.0815429 5.66569 0.081543 5.18414C0.081543 4.70259 0.471916 4.31221 0.953466 4.31221H10.0021L8.0029 2.31299C7.66239 1.97248 7.66239 1.42041 8.0029 1.0799Z" fill=""/>
</svg>
const bike=<svg width="36" height="25" viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.2443 24.1666C6.17833 24.1666 4.4468 23.4676 3.04972 22.0696C1.65166 20.6725 0.952637 18.941 0.952637 16.875C0.952637 14.809 1.66382 13.0775 3.08618 11.6804C4.50757 10.2823 6.22694 9.58331 8.2443 9.58331C10.1158 9.58331 11.6899 10.1423 12.9664 11.2604C14.2419 12.3785 15.0499 13.7639 15.3901 15.4166H16.3381L13.7131 8.12498H11.161V5.20831H18.4526V8.12498H16.8485L17.3589 9.58331H24.3589L22.2443 3.74998H18.4526V0.833313H22.2443C22.8762 0.833313 23.4416 1.00345 23.9403 1.34373C24.4381 1.68401 24.7964 2.14581 25.0151 2.72915L27.4943 9.5104H28.661C30.6783 9.5104 32.3982 10.2211 33.8206 11.6425C35.2419 13.0648 35.9526 14.7847 35.9526 16.8021C35.9526 18.8437 35.2478 20.5816 33.8381 22.0156C32.4283 23.4496 30.7026 24.1666 28.661 24.1666C26.911 24.1666 25.3739 23.6198 24.0497 22.526C22.7246 21.4323 21.8797 20.0347 21.5151 18.3333H15.3901C15.0499 20.0104 14.2235 21.4021 12.911 22.5085C11.5985 23.6139 10.0429 24.1666 8.2443 24.1666ZM8.2443 18.3333H12.3276V15.4166H8.2443V18.3333ZM19.4735 15.4166H21.5151C21.6367 14.8576 21.801 14.335 22.0081 13.8489C22.2142 13.3628 22.4874 12.9132 22.8276 12.5H18.3797L19.4735 15.4166ZM27.2026 17.3854L29.9735 16.3646L28.5151 12.5L25.8172 13.5208L27.2026 17.3854Z" fill=""/>
</svg>
const tracker=<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.1978 0.2771C13.5845 0.2771 13.9555 0.430745 14.229 0.704235C14.5024 0.977726 14.6561 1.34866 14.6561 1.73543V3.29585C16.8377 3.61118 18.8587 4.6238 20.4174 6.18245C21.9761 7.74111 22.9887 9.76217 23.304 11.9438H24.8644C25.2512 11.9438 25.6221 12.0974 25.8956 12.3709C26.1691 12.6444 26.3228 13.0153 26.3228 13.4021C26.3228 13.7889 26.1691 14.1598 25.8956 14.4333C25.6221 14.7068 25.2512 14.8604 24.8644 14.8604H23.304C22.9887 17.042 21.9761 19.0631 20.4174 20.6217C18.8587 22.1804 16.8377 23.193 14.6561 23.5084V25.0688C14.6561 25.4555 14.5024 25.8265 14.229 26.1C13.9555 26.3735 13.5845 26.5271 13.1978 26.5271C12.811 26.5271 12.44 26.3735 12.1666 26.1C11.8931 25.8265 11.7394 25.4555 11.7394 25.0688V23.5084C9.55782 23.193 7.53676 22.1804 5.97811 20.6217C4.41945 19.0631 3.40684 17.042 3.0915 14.8604H1.53109C1.14431 14.8604 0.77338 14.7068 0.49989 14.4333C0.226399 14.1598 0.0727539 13.7889 0.0727539 13.4021C0.0727539 13.0153 0.226399 12.6444 0.49989 12.3709C0.77338 12.0974 1.14431 11.9438 1.53109 11.9438H3.0915C3.40706 9.76226 4.41975 7.74132 5.97836 6.18271C7.53698 4.62409 9.55791 3.61141 11.7394 3.29585V1.73543C11.7394 1.34866 11.8931 0.977726 12.1666 0.704235C12.44 0.430745 12.811 0.2771 13.1978 0.2771ZM5.90609 13.4021C5.90609 11.4682 6.67431 9.61357 8.04177 8.24611C9.40922 6.87866 11.2639 6.11043 13.1978 6.11043C15.1316 6.11043 16.9863 6.87866 18.3537 8.24611C19.7212 9.61357 20.4894 11.4682 20.4894 13.4021C20.4894 15.336 19.7212 17.1906 18.3537 18.5581C16.9863 19.9255 15.1316 20.6938 13.1978 20.6938C11.2639 20.6938 9.40922 19.9255 8.04177 18.5581C6.67431 17.1906 5.90609 15.336 5.90609 13.4021Z" fill=""/>
</svg>
const truck=<svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.79183 28.4376V23.9897C1.35433 23.5036 1.0019 22.9626 0.734538 22.3666C0.467177 21.7716 0.333496 21.1217 0.333496 20.4168V6.56264C0.333496 4.54528 1.26926 3.06848 3.14079 2.13223C5.01232 1.19695 7.96544 0.729309 12.0002 0.729309C16.1807 0.729309 19.1703 1.17896 20.9689 2.07827C22.7675 2.97757 23.6668 4.47236 23.6668 6.56264V20.4168C23.6668 21.1217 23.5331 21.7716 23.2658 22.3666C22.9984 22.9626 22.646 23.5036 22.2085 23.9897V28.4376H17.8335V25.521H6.16683V28.4376H1.79183ZM3.25016 12.396H20.7502V8.02098H3.25016V12.396ZM6.896 21.146C7.50363 21.146 8.01989 20.9331 8.44475 20.5072C8.87058 20.0824 9.0835 19.5661 9.0835 18.9585C9.0835 18.3508 8.87058 17.8346 8.44475 17.4097C8.01989 16.9839 7.50363 16.771 6.896 16.771C6.28836 16.771 5.77211 16.9839 5.34725 17.4097C4.92141 17.8346 4.7085 18.3508 4.7085 18.9585C4.7085 19.5661 4.92141 20.0824 5.34725 20.5072C5.77211 20.9331 6.28836 21.146 6.896 21.146ZM17.1043 21.146C17.712 21.146 18.2282 20.9331 18.6531 20.5072C19.0789 20.0824 19.2918 19.5661 19.2918 18.9585C19.2918 18.3508 19.0789 17.8346 18.6531 17.4097C18.2282 16.9839 17.712 16.771 17.1043 16.771C16.4967 16.771 15.9804 16.9839 15.5556 17.4097C15.1297 17.8346 14.9168 18.3508 14.9168 18.9585C14.9168 19.5661 15.1297 20.0824 15.5556 20.5072C15.9804 20.9331 16.4967 21.146 17.1043 21.146Z" fill=""/>
</svg>
const plane=<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.8693 10.9498L28.536 18.3188V21.2355L16.8693 17.5517V25.3684L21.2443 27.798V29.9855L14.6818 28.5271L8.1193 29.9855V27.798L12.4943 25.3669V17.5502L0.827637 21.2355V18.3188L12.4943 10.9498V3.00629C12.4943 2.42613 12.7248 1.86973 13.135 1.45949C13.5452 1.04925 14.1016 0.818787 14.6818 0.818787C15.262 0.818787 15.8184 1.04925 16.2286 1.45949C16.6388 1.86973 16.8693 2.42613 16.8693 3.00629V10.9498Z" fill=""/>
</svg>


const CardData=[
    {
        title:"LOCAL SHIPMENT",
        text:"Ship with ease within Lagos and Abuja",
        title_Svg:arrow,
        Svg:bike,
        link:"/create-local-shipment",
    },
    {
        title:"INTERSTATE SHIPMENT",
        text:"Ship with ease within major cities in Nigeria",
        title_Svg:arrow,
        Svg:truck,
        link:"/create-interstate-shipment",
    },
    {
        title:"INTERNATIONAL SHIPMENT",
        text:"Ship with accross the globe",
        title_Svg:arrow,
        Svg:plane,
        link:"/create-international-shipment",
    },
]

export default CardData