
import "../../Assets/Style/Shipments.scss"
import {
    Top,
    ShipmentTable,} from "./Components"

const Shipments = () => {

    return (
        <div className="Shipments_Wrapper">
            <Top/>
            <ShipmentTable/>
        </div>
    );
}
 
export default Shipments;