import blue_eye from "../../Assets/Img/blue_eye.png"
import wallet from "../../Assets/Img/wallet.png"
import "../../Assets/Style/Dashboard.scss"
import DashboardCards from "./components/DashboardCard";
import {useEffect, useState} from "react";
import AOS from "aos";
import {LocalShipmentDataStorage} from "../../Library/LocalShipmentDataStorage";
import axios from "axios";
import {getWalletBalanceURL} from "../../Library/URLs";
import {generateTransactionID} from "../../Library/Utilities";
import { NumericFormat } from 'react-number-format';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const DashboardPages = () => {
    const [Result,setResult]=useState('');
    const [showBalance, setShowBalance] = useState(true);
    const name=localStorage.getItem('name')
    
    useEffect(()=>{
   const GetWallet=async()=>{
    const Data={
        "user_id":localStorage.getItem('user_id'),
        "user_ses_id":localStorage.getItem('session_id'),
        "company_id":localStorage.getItem('company_id'),
        "transaction_id":generateTransactionID(15),
    }
        await axios
        .post(getWalletBalanceURL,Data)
        .then(response=>{
            const res=response.data.data
            setResult(res)
        })
    }

    GetWallet()
    },[])

    return (
    <div className="Dashboard_Wrapper">
        <h3>Welcome, {name}</h3>
        <div className="Banner">
            <div className="Inner_Card">
                <div className="Top_Box">
                    <div className="Left">
                        <h4>SPENT</h4>
                        <div className="Wrap">
                        {
                            showBalance ? (
                                <><h2><NumericFormat value={Result.total_spent} displayType={'text'} thousandSeparator={true} prefix={''} /></h2><h6>NGN</h6></>
                            ) : (
                                <h2>**********</h2>
                            )
                        }
                        </div>
                    </div>
                    <div className="Right">
                        {
                            showBalance ? (
                                <FaEyeSlash onClick={()=>setShowBalance(false)} />
                            ) : (
                                <FaEye onClick={()=>setShowBalance(true)} />
                            )
                        }
                    </div>
                </div>
                <div className="Bottom_Box">
                {
                    showBalance ? (
                        <h3><NumericFormat value={Result.account_balance} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h3>
                    ) : (
                        <h3>**********</h3>
                    )
                }
                    <div className="Right">
                        <div className="Btn">
                        AVAILABLE BALANCE
                        </div>
                        <div className="Img_Box">
                            <img src={wallet} alt="avatar" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DashboardCards/>
    </div>
    );
}
 
export default DashboardPages;