import { createSlice } from '@reduxjs/toolkit';

// ==============================|| REDUX - MAIN STORE ||============================== //

const InterStateSlice = createSlice({
    name: 'InterStateSlice',
    initialState: {
        Overall: '',
        isLoading: false,
        isBook: false,
        PriceDetails: '',
        PaymentOption: '',
        transaction_id: '',
        Sender: '',
        Receiver: '',
        Package: [
            {
                package_items: [],
                package_weight: "0",
                package_length: "",
                package_width: "",
                description: "",
                package_height: "",
                package_value: "0",
                number_of_items: "0"
            }
        ],
        RequestPack: {},
        TotalQuantity: 0,
        TotalValue: 0,
        TotalWeight: 0,
        InterStateStep: 0
    },
    reducers: {
        GetPriceDetails: (state, action) => {
            state.PriceDetails = action.payload
        },
        GetOverall: (state, action) => {
            state.Overall = action.payload
        },
        TogglePrice: (state, action) => {
            state.PriceDisplay = action.payload
        },
        Loading: (state, { payload }) => {
            state.isLoading = payload
        },
        ShipmentStatus: (state, { payload }) => {
            state.isBook = payload
        },
        prevStep: (state) => {
            if (state.InterStateStep === 0) {
                return 0
            }
            else {
                state.InterStateStep -= 1
            }
        },
        GetPayment: (state, action) => {
            state.PaymentOption = action.payload
        },
        GetQtyVal: (state) => {
            let q = state.Package.map(item => { return item.quantity })
            let sum_q = 0
            for (let a of q) {
                sum_q += +a
            }
            state.TotalQuantity = sum_q

            let v = state.Package.map(item => { return item.value })
            let sum_v = 0
            for (let a of v) {
                sum_v += +a
            }
            state.TotalValue = sum_v

            let w = state.Package.map(item => { return item.weight })
            let sum_w = 0
            for (let a of w) {
                sum_w += +a
            }
            state.TotalWeight = sum_w
        },
        GetSender(state, action) {
            state.Sender = action.payload
        },
        GetReceiver(state, action) {
            state.Receiver = action.payload
        },
        GetPackage(state, { payload }) {
            const Index = state.Package.findIndex((item) => item.name === payload.name)
            if (Index >= 0) {
                state.Package[Index].name = payload.name
                state.Package[Index].value = payload.value
                state.Package[Index].description = payload.description
                state.Package[Index].weight = payload.weight
                state.Package[Index].quantity = payload.quantity
                state.Package[Index].currency = payload.currency
            }
            else {
                state.Package.push(payload)
            }
        },
        ResetPackage(state, action) {
            state.Package = action.payload
        },
        ResetTotalQuantity(state, action) {
            state.TotalQuantity = action.payload
        },
        ResetTotalValue(state, action) {
            state.TotalValue = action.payload
        },
        ResetTotalWeight(state, action) {
            state.TotalWeight = action.payload
        },
        GetRequestPack(state) {
            const data = state.Package
            let object = data.reduce((acc, current, i) => {
                acc[i] = current;
                return acc;
            }, {});
            state.RequestPack = object
        },
        EditPackage(state, action) {
            state.Package = state.Package
                .map((item) => {
                    if (item.id === action.payload.id) {
                        item.name = action.payload.name
                        item.value = action.payload.value
                        item.description = action.payload.description
                        item.weight = action.payload.weight
                        item.quantity = action.payload.quantity
                        item.currency = action.payload.currency
                    }
                    return item
                })
        },
        UpdatePackage(state, action) {
            state.Package = action.payload
        }
    }
});

export const InterStateActions = InterStateSlice.actions

export default InterStateSlice.reducer

