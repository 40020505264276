import TabCard from "./TabCard";
import Data from "./TabDetails"
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const NavigatingTabs = ({Tab,setTab, shipmentFee}) => {
     const pickTab=(index)=>{
         if (shipmentFee !== ""){
             setTab(index)
         }
     }

     useEffect(() => {
        AOS.init({
          duration : 2000
        });
        AOS.refresh();
      }, []);

    return (
        <div data-aos="fade-zoom-in" className="Tabs_Wrap">
            {
                Data.map((item,index)=><TabCard 
                handle={()=>pickTab(index)}
                Target={index}
                Selected={Tab}
                active='active'
                key={index}
                item={item}
                />)
            }
        </div>
    );
}
 
export default NavigatingTabs;