import {useEffect, useRef, useState} from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import {ResetPWDURL} from "../../../Library/URLs";
import {generateTransactionID} from "../../../Library/Utilities";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LocalShipmentActions } from '../../../store/Local_Slice';

const ResetPWD = () => {
    const dispatch=useDispatch()

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const form = useRef(null);

    function getDevice() {
        let operatingSystem = 'Not known';
        if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
        if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
        if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
        if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

        let currentBrowser = 'Not known';
        if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Chrome'; }
        else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
        else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
        else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
        else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
        else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
        else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }

        return currentBrowser + " on " + operatingSystem;
    }

    const loginUser = (e) => {
        e.preventDefault();

        const data = new FormData(form.current);

        const formValues = {};

        for (let [key, value] of data.entries()) {
            formValues[key] = value;
        }
        formValues["device_id"] = "value";
        formValues["device_name"] = getDevice();
        formValues["token"] = "value";
        formValues["company_id"] = "44";
        formValues["transaction_id"] = generateTransactionID(15);

        console.log(formValues);

        setIsSubmitting(true);

        axios
            .post(ResetPWDURL, formValues)
            .then(function (response) {

                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);
                    toast.error(response["data"]["message"])
                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    toast.success(response["data"]["message"])
                    dispatch(LocalShipmentActions.GetResetStatus(false))
                    navigate('/login', {replace: true});
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                toast.error('Reset error message'+error.message)
                return error;
            });
    };



    return (
        <div className="Login_Form">
        <h3>Let's get started</h3>
        <p>To continue, please provide a valid details.</p>
        <form  ref={form} onSubmit={loginUser}>
            <div className="Box">
                <input placeholder="Enter new password" name="new_password" type="password"
                 />
            </div>
            <div className="Box Reset_Middle">
                <input placeholder="Enter sent code" name="reset_code" type="tel"
                />
            </div>
            <div className="Box Reset_Bottom">
                <input placeholder="Enter email" name="email" type="email"
                />
            </div>

            <Link className="Forgot_Text" to="/login">Already have an account</Link>

            <div className="Terms">
                By completing this transaction, you represent that you have read, understand and accept the
                Zee Express <Link to="/login">terms of service</Link> and <Link to="/login">privacy policy</Link>.
            </div>

            <button type="submit" className="Btn">
                {isSubmitting === false ? (
                    <span className="button-text">Reset</span>
                ) : (
                    <span className="spinner-border spinner-grow-sm"/>
                )}
            </button>
        </form>
    </div>
    );
}
 
export default ResetPWD;