


const TabCard = ({item,active,Target,Selected}) => {
    const {title,subtext,active_img,inactive_img}=item

    return ( 
        <div className={`Tab_Card ${Target===Selected ? active : ''}`}>
            <div className="Left">
                <h4>{title}</h4>
                <h5>{subtext}</h5>
            </div>
            <img src={Target===Selected ? active_img : inactive_img} alt="avatar" />
        </div>
    );
}
 
export default TabCard;