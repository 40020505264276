import axios from 'axios'
import { LocalShipmentActions } from './Local_Slice'
import { toast } from 'react-toastify';
import {
  createLocalShipment,
} from '../Library/URLs'


export const BookLocalShipment = (Data) => {
  return async (dispatch) => {
    dispatch(LocalShipmentActions.Loading(true))
    console.log('book', Data)
    const BookShipment = async () => {
      let data

      const res = await axios.post(createLocalShipment, Data) // returns promise
      data = res.data
      return data
    }
    try {
      const BookInterState = await BookShipment()
      console.log('book', JSON.stringify(BookInterState))
      if (BookInterState.success === false) {
        dispatch(LocalShipmentActions.ShipmentStatus(false))
        toast.error(BookInterState.message)
      }
      if (BookInterState.success === true) {
        dispatch(LocalShipmentActions.ShipmentStatus(true))
        toast.success('Shipment as been successfully book')
      }
      dispatch(LocalShipmentActions.Loading(false))
    } catch (error) {
      console.log('Shipment Book error' + error.message)
      toast.error('Shipment Book error' + error.message)
      dispatch(LocalShipmentActions.Loading(false))
    }
  }
}

export const ClearRecord = () => {
  return async (dispatch) => {
    dispatch(LocalShipmentActions.ShipmentStatus(false))
    dispatch(LocalShipmentActions.GetFullDetails(''))
    dispatch(LocalShipmentActions.GetLocation(''))
    dispatch(LocalShipmentActions.GetPriceDetails(''))
    dispatch(LocalShipmentActions.GetPayment(''))
    localStorage.removeItem('pickupLocation')
    localStorage.removeItem('dropoffLocation')
  }
}
