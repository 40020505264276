import React from "react";
import "../../../Assets/Style/Benefits.scss";
import globe from "../../../Assets/Img/globe.svg";
import clock from "../../../Assets/Img/clock.svg";
import headset from "../../../Assets/Img/headset.svg";
import { Link } from "react-router-dom";

const Benefits = () => {
  return (
    <div className="BenefitWrap">
      <h3 className="Title">Benefits</h3>
      <div className="BenefitsSection">
        <div className="GridSection">
          {/* Column 1 */}
          <div className="BenefitCard">
            <img src={globe} alt="globe" className="BenefitIcon" />
            <p className="BenefitName">Local & International</p>
            <p className="description">
              Inter-state delivery within Nigeria and same-day delivery within
              Lagos, Ibadan, and Abuja.
            </p>
            <Link to="#" className="BenefitLink">
              Learn More
            </Link>
          </div>
          {/* Column 2 */}
          <div className="BenefitCard">
            <img src={clock} alt="clock" className="BenefitIcon" />
            <p className="BenefitName">On Time Delivery</p>
            <p className="description">
              Our shipping options has been tailored made to provide you with
              the best delivery times for your shipment.
            </p>
            <Link to="#" className="BenefitLink">
              Learn More
            </Link>
          </div>
          {/* Column 3 */}
          <div className="BenefitCard">
            <img src={headset} alt="headset" className="BenefitIcon" />
            <p className="BenefitName">Customer Service Support</p>
            <p className="description">
              Fast and responsive customer service support team to respond to
              all your shipping needs.
            </p>
            <Link to="#" className="BenefitLink">
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
