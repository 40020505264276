import { Fragment } from "react";

const WrongRoutePage = () => {
    return (
        <Fragment>
              <h1>Wrongs Route</h1>
        </Fragment>
    );
}
 
export default WrongRoutePage;