import { useState } from "react";
import "../../../Assets/Style/Confirm.scss"
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionID } from "../../../Library/Utilities";
import { BookInterStateShipment, ClearRecord } from "../../../store/InterStateAction";

const ConfirmSection = ({ set }) => {
    const dispatch = useDispatch()

    const user_id = localStorage.getItem('user_id');
    const user_ses_id = localStorage.getItem('session_id')
    const company_id = localStorage.getItem('company_id')
    const mail = localStorage.getItem('email')
    const transaction_id = generateTransactionID(15)
    const {
        isLoading,
        isBook,
        Sender,
        Receiver,
        Package,
        TotalValue,
        PaymentOption,
        PriceDetails,
        TotalWeight,
        Overall,
        RequestPack, } = useSelector((state) => state.Interstate)




    const HomeHandle = () => {
        dispatch(ClearRecord())
        set(0)
    }

    // This function gets the description of all items in the package
    // and concatenate into a string
    function getDescriptionString(array) {
        return array.map(obj => obj.description).join(', ');
    };

    const BookShipment = () => {
        const data = {
            "packages": Package,
            "user_id": user_id,
            "transaction_id": transaction_id,
            "company_id": company_id,
            "user_ses_id": user_ses_id,
            "processor": PriceDetails?.carrier_name,
            "payment_method": PaymentOption,
            "sender_first_name": Sender.first_name,
            "sender_last_name": Sender.last_name,
            "sender_phone_no": Sender.mobile,
            "sender_postcode": Sender?.post_code,
            "sender_address_1": Sender.address_one,
            "sender_address_2": Sender.address_two,
            "pick_up_location": Sender.address_one,
            "sender_city": Sender.city,
            "sender_state": Sender.state,
            "sender_country": "Nigeria",
            "sender_email": Sender?.sender_email,
            "drop_off_location": Receiver.address_one,
            "recipient_address_1": Receiver.address_one,
            "recipient_address_2": Receiver.address_two,
            "recipient_first_name": Receiver.first_name,
            "recipient_last_name": Receiver.last_name,
            "recipient_phone_no": Receiver.mobile,
            "recipient_email": Receiver.recipient_email,
            "description": getDescriptionString(allPackageItems),
            "request_type": "Interstate",
            "recipient_postcode": Receiver?.post_code,
            "recipient_city": Receiver.city,
            "recipient_state": Receiver.state,
            "recipient_country": "Nigeria",
            "weight": packageWeight,
            "amount": PriceDetails?.selling_price?.replaceAll(",", ""),
            "flutterwave_amount": PriceDetails?.selling_price?.replaceAll(",", ""),
            "carrier_cost_price": PriceDetails?.selling_price?.replaceAll(",", ""),
            "item_value": packageValue,
            "item_value_currency": "NGN",
            booking_type: "Now",
            "length": null,
            "breadth": null,
            "height": null,
            "insurance": "no",
            "origin_of_content": null,
            "request_pickup": "No",
            "transaction_reference_no": "",
            "carrier_id": "1",
            "pickup_address_id": "",
            "dropoff_address_id": "",
            "parcel_id": "",
            "request_pickup_carrier_id": "",
            "request_pickup_address_id": "",
            "request_dropoff_address_id": ""
        }
        dispatch(BookInterStateShipment(data))
    }



    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    let packageValue = Package?.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.package_value);
    }, 0);

    let packageWeight = Package?.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.package_weight);
    }, 0);

    let allPackageItems = [];

    // Outer loop to iterate through the varObj array
    for (let i = 0; i < Package.length; i++) {
        // Inner loop to iterate through the sports array for each object
        for (let j = 0; j < Package[i].package_items.length; j++) {
            // Push each sport object into the allSports array
            allPackageItems.push(Package[i].package_items[j]);
        }
    }

    return (
        <>
            {!isBook && <div data-aos="fade-zoom-in" className="Confirm">
                <h2>SUMMARY</h2>
                <div className="Box">
                    <p>SENDER DETAILS</p>
                    <div className="Right">
                        <h4>{Sender.first_name} {Sender.last_name}</h4>
                        <h6>{Sender.address_one}</h6>
                    </div>
                </div>
                <div className="Box">
                    <p>RECEIVER DETAILS</p>
                    <div className="Right">
                        <h4>{Receiver.first_name} {Receiver.last_name}</h4>
                        <h6>{Receiver.address_one}</h6>
                    </div>
                </div>
                <div className="Box">
                    <p>DECLARED WEIGHT</p>
                    <div className="Right">
                        <h4>{packageWeight}KG</h4>
                        {/* <h6>1CM X 1CM X 1CM</h6> */}
                    </div>
                </div>
                <div className="Box">
                    <p>PACKAGE</p>
                    <div className="Right">
                        {allPackageItems.map((item, index) => (
                            <h6 key={index}>{item.description} </h6>
                        ))}
                    </div>
                </div>
                <div className="Box">
                    <p>PACKAGE VALUE</p>
                    <div className="Right">
                        <h4>{allPackageItems[0]?.currency==="USD"?"$":"₦"}{parseFloat(packageValue).toLocaleString("en-US",{maximumFractionDigits:2})}</h4>
                    </div>
                </div>
                <div className="Box">
                    <p>PAYMENT MENTHOD</p>
                    <div className="Right">
                        <h4>{PaymentOption}</h4>
                    </div>
                </div>
                <div className="Bottom_Box">
                    <div className="Left">
                        By continuing, I represent that the declaration above is a proper and accurate description of the contents of my package.
                    </div>
                    <div className="Right">
                        <h3>NGN {PriceDetails.amount}</h3>
                        <h5>Inc. VAT</h5>
                    </div>
                </div>
                <button onClick={BookShipment}>
                    {
                        isLoading === true ? <span className="spinner-border spinner-grow-sm" /> : 'BOOK SHIPMENT'
                    }
                </button>
            </div>}
            {
                isBook && <div data-aos="fade-zoom-in" className="Successfull">
                    <h4>Shipment as been book Successfully</h4>
                    <p>You can go ahead</p>
                    <p>to track your delivery</p>
                    <div onClick={HomeHandle} className="Btn">Back</div>
                </div>
            }
        </>
    );
}

export default ConfirmSection;