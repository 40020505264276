const data =[
    {
     name:"Dashboard",
     link:"/dashboard",
    },
    {
    name:"Shipments",
    link:"/shipments",
    },
    {
    name:"Wallet",
    link:"/payments",
    },
    // {
    // name:"Imports",
    // link:"/",
    // },
]
export default data