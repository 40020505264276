import { useEffect, useState } from "react";
import axios from "axios";
import {getWalletBalanceURL} from "../../../Library/URLs";
import {generateTransactionID} from "../../../Library/Utilities";
import { NumericFormat } from 'react-number-format';
import { FaEye, FaEyeSlash } from "react-icons/fa";


const TransTab = () => {
    const [showBalance, setShowBalance] = useState(true);

    const [Result,setResult]=useState('');

    
    useEffect(()=>{
   const GetWallet=async()=>{
    const Data={
        "user_id":localStorage.getItem('user_id'),
        "user_ses_id":localStorage.getItem('session_id'),
        "company_id":localStorage.getItem('company_id'),
        "transaction_id":generateTransactionID(15),
    }
        await axios
        .post(getWalletBalanceURL,Data)
        .then(response=>{
            const res=response.data.data
            setResult(res)
        })
    }

    GetWallet()
    },[])

    

    return (
        <div className="Wallet_Tab">
        <div className="Box">
            <div className="Left">
                <h4>BALANCE</h4>
                <div className="Wrap">
                {
                    showBalance ? (
                        <><h2><NumericFormat value={Result.account_balance} displayType={'text'} thousandSeparator={true} prefix={''} /></h2><h6>NGN</h6></>
                    ) : (
                        <h2>**********</h2>
                    )
                }
                </div>
            </div>
            <div className="Right">
            <div className="Svg_Box">
            {
                showBalance ? (
                    <FaEyeSlash onClick={()=>setShowBalance(false)} />
                ) : (
                    <FaEyeSlash onClick={()=>setShowBalance(true)} />
                )
            }
            </div>
            </div>
        </div>
        <div className="Box">
            <div className="Left">
                {
                    showBalance ? (
                        <h2><NumericFormat value={Result.total_funded} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h2>
                    ) : (
                        <h2>**********</h2>
                    )
                }
            </div>
            <div className="Right">
                <div className="Box">TOTAL FUNDED</div>
                <div className="Svg_Box">
                <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.54382 2.16483C1.54382 2.0816 1.57773 2.00178 1.63809 1.94293C1.69845 1.88408 1.78032 1.85101 1.86569 1.85101H8.62491C8.79564 1.85101 8.95937 1.78489 9.0801 1.66718C9.20082 1.54948 9.26864 1.38984 9.26864 1.22338C9.26864 1.05692 9.20082 0.897282 9.0801 0.779578C8.95937 0.661874 8.79564 0.595749 8.62491 0.595749H1.86569C1.43886 0.595749 1.02952 0.761062 0.727712 1.05532C0.425902 1.34958 0.256348 1.74868 0.256348 2.16483V9.3826C0.256348 9.71552 0.391991 10.0348 0.633439 10.2702C0.874887 10.5056 1.20236 10.6379 1.54382 10.6379H10.5561C10.8976 10.6379 11.225 10.5056 11.4665 10.2702C11.7079 10.0348 11.8436 9.71552 11.8436 9.3826V3.73391C11.8436 3.40099 11.7079 3.08171 11.4665 2.8463C11.225 2.6109 10.8976 2.47865 10.5561 2.47865H1.86569C1.78032 2.47865 1.69845 2.44558 1.63809 2.38673C1.57773 2.32788 1.54382 2.24806 1.54382 2.16483ZM8.30304 7.4997C8.55913 7.4997 8.80474 7.40051 8.98582 7.22396C9.16691 7.0474 9.26864 6.80794 9.26864 6.55825C9.26864 6.30857 9.16691 6.06911 8.98582 5.89255C8.80474 5.71599 8.55913 5.61681 8.30304 5.61681C8.04694 5.61681 7.80134 5.71599 7.62025 5.89255C7.43917 6.06911 7.33744 6.30857 7.33744 6.55825C7.33744 6.80794 7.43917 7.0474 7.62025 7.22396C7.80134 7.40051 8.04694 7.4997 8.30304 7.4997Z" fill="white"/>
                </svg>
                </div>
            </div>
        </div>
        <div className="Box">
            <div className="Left">
                {
                    showBalance ? (
                        <h2><NumericFormat value={Result.total_spent} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h2>
                    ) : (
                        <h2>**********</h2>
                    )
                }
            </div>
            <div className="Right">
                <div className="Box">TOTAL SPENT</div>
                <div className="Svg_Box">
                <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.54382 2.16483C1.54382 2.0816 1.57773 2.00178 1.63809 1.94293C1.69845 1.88408 1.78032 1.85101 1.86569 1.85101H8.62491C8.79564 1.85101 8.95937 1.78489 9.0801 1.66718C9.20082 1.54948 9.26864 1.38984 9.26864 1.22338C9.26864 1.05692 9.20082 0.897282 9.0801 0.779578C8.95937 0.661874 8.79564 0.595749 8.62491 0.595749H1.86569C1.43886 0.595749 1.02952 0.761062 0.727712 1.05532C0.425902 1.34958 0.256348 1.74868 0.256348 2.16483V9.3826C0.256348 9.71552 0.391991 10.0348 0.633439 10.2702C0.874887 10.5056 1.20236 10.6379 1.54382 10.6379H10.5561C10.8976 10.6379 11.225 10.5056 11.4665 10.2702C11.7079 10.0348 11.8436 9.71552 11.8436 9.3826V3.73391C11.8436 3.40099 11.7079 3.08171 11.4665 2.8463C11.225 2.6109 10.8976 2.47865 10.5561 2.47865H1.86569C1.78032 2.47865 1.69845 2.44558 1.63809 2.38673C1.57773 2.32788 1.54382 2.24806 1.54382 2.16483ZM8.30304 7.4997C8.55913 7.4997 8.80474 7.40051 8.98582 7.22396C9.16691 7.0474 9.26864 6.80794 9.26864 6.55825C9.26864 6.30857 9.16691 6.06911 8.98582 5.89255C8.80474 5.71599 8.55913 5.61681 8.30304 5.61681C8.04694 5.61681 7.80134 5.71599 7.62025 5.89255C7.43917 6.06911 7.33744 6.30857 7.33744 6.55825C7.33744 6.80794 7.43917 7.0474 7.62025 7.22396C7.80134 7.40051 8.04694 7.4997 8.30304 7.4997Z" fill="white"/>
                </svg>
                </div>
            </div>
        </div>
    </div>
    );
}
 
export default TransTab;