import Button from "../../../components/Button";
import logo from "../../../Assets/Img/zeelogo.png"
import { Link } from "react-router-dom";

const LoginHeader = () => {
    return (
        <div className="Login_Header">
            <Link to="/"><img src={logo} alt="avatar" /></Link>
           <Link to="/register"><Button color="#225AA4" text="Register" width="107px"/></Link>
        </div>
    );
}
 
export default LoginHeader;