import React from "react";
import "../../../Assets/Style/Plugin.scss";
import PluginIllustrate from "../../../Assets/Img/PluginIllustrate.png";
import Shopify from "../../../Assets/Img/Shopify.png";
import Woo from "../../../Assets/Img/WOO.png";

const Plugin = () => {
  return (
    <div className="PluginSection">
      <div className="Left">
        <img
          src={PluginIllustrate}
          alt="plugin-illustrate"
          className="Illustrate"
        />
      </div>
      <div className="Right">
        <h2 className="PluginHeader">INTEGRATE YOUR E-COMMERCE PLUGIN</h2>
        <p className="TopText">
          Say goodbye to booking customer deliveries manually. Compatible with
          WordPress, WooCommerce, Shopify, and more. No coding is required.
        </p>
        <p>
          Integrate global shipping into your app or website. Send custom
          branded shipment notifications to your customers. Developer-friendly.
        </p>
        <div className="PluginBannerContain">
          <div className="PluginBanner">
            <img src={Shopify} alt="shopify" />
          </div>
          <div className="PluginBanner">
            <img src={Woo} alt="woo commerce" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plugin;
