import { useState } from "react";
import Card from "./Card";
import Data from "./index"


const DashboardCards = () => {
    const [Target,setTarget]=useState()
    const Select=(index)=>setTarget(index)
    return (
        <div className="Cards_Wrapper">
            {
                Data.map((item,index)=>
                    <Card
                key={index}
                handleClick={()=>Select(index)} active={Target===index ? 'active':''} item={item}/>)
            }
        </div>
    );
}
 
export default DashboardCards;